import { create } from "zustand";

export const useWebStore = create((set, get) => {
  return {
    // START WEBSITE
    // from query string
    params: {},

    // powers "mode", can come from query string or click interactions
    // none, login
    route: "none",

    isTouchSupported: false,
    // END WEBSITE

    // START APPWRITE
    //from session
    userAccount: null,

    //from database
    userData: null,

    // tells page's useEffects to refresh their use of userData, like JSON strings
    refreshUserData: false,

    // handled by router, matches localStorage
    loggedIn: false,

    // handled by router, matches localStorage
    session: {},
    // END APPWRITE
  };
});
