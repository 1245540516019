import { create } from "zustand";

export const useFishingStore = create(() => {
  return {
    // START FISH GAME
    canCast: false,
    equipmentCheck: true,
    isQTEPhase: false,
    qteScore: 0,
    qteTargetDuration: 3,
    qteClock: 0,
    qtePressed: false,
    equipmentPower: 0,
    maxQTEPower: 0,
    baitItemID: "abc123",
    // END FISH GAME
  };
});
