import { items } from "./constants/items";

export const sortInventory = (inventory) => {
  const sortedItems = Object.keys(inventory)
    .map((itemId) => ({
      itemId,
      type: items[itemId].type,
      equipped: inventory[itemId].equipped,
    }))
    .sort((a, b) => {
      // Sort by equip status (equipped items first)
      if (a.equipped !== b.equipped) {
        return a.equipped === "Unequipped" ? 1 : -1;
      }

      // Sort by type if both are either equipped or unequipped
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;

      return 0;
    });

  return sortedItems;
};
