import { Button, Typography } from "@mui/material";
import React from "react";
import { useStore } from "../../zustand";
import { useNavigate } from "react-router-dom";

const Connect = () => {
  const navigate = useNavigate();
  const isInWaitingArea = useStore((state) => state.isInWaitingArea);
  const isLevelChosen = useStore((state) => state.isLevelChosen);

  return (
    <>
      <div>
        <Typography>Iglooverse Connect</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/landing");
          }}
        >
          Landing
        </Button>
        <Button
          variant="contained"
          disabled={!isInWaitingArea || isLevelChosen}
          onClick={() => {
            useStore.setState({ levelChosen: "hub", isLevelChosen: true });
          }}
        >
          Join Hub
        </Button>
      </div>
    </>
  );
};

export default Connect;
