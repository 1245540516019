import { useWebStore } from "../../zustand/web";
import { getDocumentViaAttribute } from "../CRUD";
import { devMsg } from "../devMsg";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

/**
 * This function updates the user data in the zustand store.
 * It fetches the user data from the database using the provided user id.
 * If the user data is found, it updates the zustand store state with the fetched user data and sets the refreshUserData flag to true.
 *.
 * @async
 * @function
 * @param {string} id - The user id to search for in the user_data database.
 */
const UpdateUserData = async (id) => {
  const querysearch = await getDocumentViaAttribute(
    REACT_APP_DATABASE_ID,
    REACT_APP_IGLOO_DATA_ID,
    "user_id",
    id,
  );

  if (querysearch.total === 1) {
    devMsg("Updated userData");
    useWebStore.setState({
      userData: querysearch.documents[0],
      refreshUserData: true,
    });
  } else {
    return { error: true, errorData: "Not exactly 1 result returned" };
  }
};

export { UpdateUserData };
