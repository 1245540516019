import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { account } from "./lib/appwrite";

import Landing from "./landing";
import HelloWorld from "./HelloWorld";
import Vik128 from "./vik128writer";
import LoadingCurtain from "./LoadingCurtain";
import Game from "./game";

import Welcome from "./login/welcome";
import MainLogin from "./login/main";
import Register from "./login/register";
import Logout from "./login/logout";

import Profile from "./account/profile";

import { useStore } from "./zustand";
import { useWebStore } from "./zustand/web";

import { getDocumentViaAttribute } from "./utility/CRUD";
import { devMsg } from "./utility/devMsg";
import { useProgressionStore } from "./zustand/progression";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [routerFinished, setRouterFinished] = useState(false);

  useEffect(() => {
    devMsg("Router useEffect ran");
    setRouterFinished(false);

    let redirected = false;

    let foundRoute = false;
    let authNeeds = "any";

    const RouteGuardian = [
      { pathStart: "/landing", authNeeds: "any" },
      { pathStart: "/helloworld", authNeeds: "any" },
      { pathStart: "/vik128", authNeeds: "any" },
      { pathStart: "/login", authNeeds: "none" },
      { pathStart: "/account", authNeeds: "user" },
      { pathStart: "/game", authNeeds: "gamer" },
    ];

    const checkUserSession = async () => {
      try {
        const session = await account.getSession("current");
        return session;
      } catch (error) {
        console.log("No active session", error);
        return "error";
      }
    };

    const handleNone = async () => {
      const session = await checkUserSession();
      if (session !== "error") {
        navigate("/");
      }
    };

    const handleAny = async () => {
      const session = await checkUserSession();

      if (session === "error") {
        useWebStore.setState({ loggedIn: false, session: {} });
      } else {
        const userSearch = await getDocumentViaAttribute(
          REACT_APP_DATABASE_ID,
          REACT_APP_IGLOO_DATA_ID,
          "user_id",
          session.userId,
        );

        if (userSearch.total === 1) {
          const userAccount = await account.get();
          useWebStore.setState({
            userAccount: userAccount,
            userData: userSearch.documents[0],
            session: session,
            loggedIn: true,
          });
        } else {
          console.log("BIG ERROR MISMATCH");
          useWebStore.setState({
            userAccount: {},
            userData: {},
            session: {},
            loggedIn: false,
          });
        }
      }
    };

    const handleUser = async () => {
      const session = await checkUserSession();

      if (session === "error") {
        useWebStore.setState({ loggedIn: false, session: {} });
        navigate("/");
      } else {
        const userSearch = await getDocumentViaAttribute(
          REACT_APP_DATABASE_ID,
          REACT_APP_IGLOO_DATA_ID,
          "user_id",
          session.userId,
        );

        if (userSearch.total === 1) {
          const userAccount = await account.get();

          useWebStore.setState({
            userAccount: userAccount,
            userData: userSearch.documents[0],
            session: session,
            loggedIn: true,
          });
        } else {
          console.log("BIG ERROR MISMATCH");
          useWebStore.setState({
            userAccount: {},
            userData: {},
            session: {},
            loggedIn: false,
          });
        }
      }
    };

    const handleGamer = async () => {
      const session = await checkUserSession();

      if (session === "error") {
        useWebStore.setState({ loggedIn: false, session: {} });
        navigate("/");
      } else {
        const userSearch = await getDocumentViaAttribute(
          REACT_APP_DATABASE_ID,
          REACT_APP_IGLOO_DATA_ID,
          "user_id",
          session.userId,
        );

        if (userSearch.total === 1 && userSearch.total === 1) {
          const userAccount = await account.get();

          let parsed = {};
          let parsed1 = {};

          let parsedInventory = {};
          let parsedProgression = {};
          let parsedFeats = {};

          const storedObject = localStorage.getItem("gamepadMappings");

          if (storedObject) {
            parsed = JSON.parse(storedObject);
          }

          const storedObject1 = localStorage.getItem("frozenAxes");

          if (storedObject1) {
            parsed1 = JSON.parse(storedObject1);
          }

          parsedInventory = JSON.parse(
            userSearch.documents[0].inventory,
          ).inventory;

          parsedProgression = JSON.parse(
            userSearch.documents[0].progression,
          ).progression;

          parsedFeats = JSON.parse(userSearch.documents[0].feats);

          // console.log(parsedInventory);

          useWebStore.setState({
            userAccount: userAccount,
            userData: userSearch.documents[0],
            session: session,
            loggedIn: true,
          });
          useStore.setState({
            gamepadMappings: parsed,
            frozenAxes: parsed1,
            inventory: parsedInventory,
            progression: parsedProgression,
            gems: userSearch.documents[0].gems,
            gold: userSearch.documents[0].gold,
            inventoryDocID: userSearch.documents[0].$id,
          });
          useProgressionStore.setState({
            overallXP: parsedProgression.overall,
            fishingXP: parsedProgression.fishing,
            combatXP: parsedProgression.combat,
            feats: parsedFeats.feats,
          });
        } else {
          console.log("BIG ERROR MISMATCH");
          useWebStore.setState({
            userAccount: {},
            userData: {},
            session: {},
            loggedIn: false,
          });
        }
      }
    };

    const handleRoutes = async () => {
      for (let i = 0; i < RouteGuardian.length; i++) {
        if (!foundRoute) {
          if (location.pathname.startsWith(RouteGuardian[i].pathStart)) {
            foundRoute = true;
            authNeeds = RouteGuardian[i].authNeeds;
          }
        }
      }

      switch (location.pathname) {
        case "/login":
          redirected = true;
          navigate("/login/welcome");
          break;
        case "/":
          redirected = true;
          navigate("/landing");
          break;
        default:
          break;
      }

      if (!redirected) {
        switch (authNeeds) {
          case "any":
            await handleAny();
            break;
          case "none":
            await handleNone();
            break;
          case "user":
            await handleUser();
            break;
          case "gamer":
            await handleGamer();
            break;
          default:
            await handleAny();
            break;
        }
      }

      useStore.getState().resetGameState_action();
      setRouterFinished(true);
    };

    handleRoutes();
  }, [location, navigate]);

  return (
    <>
      <Routes>
        {routerFinished && (
          <>
            <Route path="/landing" element={<Landing />} />

            <Route path="/helloworld" element={<HelloWorld />} />

            <Route path="/vik128" element={<Vik128 />} />

            <Route path="/login/logout" element={<Logout />} />

            <Route path="/login/welcome" element={<Welcome />} />

            <Route path="/login/main" element={<MainLogin />} />

            <Route path="/login/register" element={<Register />} />

            <Route path="/account/profile" element={<Profile />} />

            <Route path="/game/play" element={<Game />} />
          </>
        )}

        {!routerFinished && <Route path="/*" element={<LoadingCurtain />} />}
      </Routes>
    </>
  );
};

export { Router };
