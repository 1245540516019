import React from "react";

import GameApp from "./game";
import { LowestBox } from "../mui/reusable";
import { useStore } from "../zustand";
import Connect from "./connect";
import { SocketManager } from "./SocketManager";

const GameWorld = () => {
  const isGameTenant = useStore((state) => state.isGameTenant);

  return (
    <LowestBox>
      <SocketManager>
        {!isGameTenant && <Connect />}
        {isGameTenant && <GameApp />}
      </SocketManager>
    </LowestBox>
  );
};

export default GameWorld;
