import { ID, databases } from "../../lib/appwrite";
import { Query } from "appwrite";

const getDocumentViaAttribute = async (
  databaseID,
  collectionID,
  key,
  value,
) => {
  try {
    const response = await databases.listDocuments(databaseID, collectionID, [
      Query.equal(key, value),
    ]);

    return response;
  } catch (error) {
    console.error("getDocumentViaAttribute failed:", error);

    return { error: true, errorData: error };
  }
};

const createDocument = async (databaseID, collectionID, data) => {
  try {
    const response = await databases.createDocument(
      databaseID,
      collectionID,
      ID.unique(),
      data,
    );

    return response;
  } catch (error) {
    console.error("getDocumentViaAttribute failed:", error);

    return { error: true, errorData: error };
  }
};

const updateDocument = async (databaseID, collectionID, documentID, data) => {
  try {
    const response = await databases.updateDocument(
      databaseID,
      collectionID,
      documentID,
      data,
    );

    return response;
  } catch (error) {
    console.error("getDocumentViaAttribute failed:", error);

    return { error: true, errorData: error };
  }
};

export { getDocumentViaAttribute, createDocument, updateDocument };
