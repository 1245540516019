import React from "react";
import { Box, Button, Typography, Stack, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();

  const login = async () => {
    navigate("/login/main");
  };

  const register = async () => {
    navigate("/login/register");
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="center">
        <Paper>
          <Box sx={{ margin: 2 }}>
            <Stack spacing={1} direction="column">
              <Typography variant="h6">
                Welcome! Please choose an action.
              </Typography>

              <Box>
                <Stack spacing={1} direction="row">
                  <Button color="primary" variant="contained" onClick={login}>
                    Login
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={register}
                  >
                    Register
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Welcome;
