import React from "react";
import { Paper, Typography, Button, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="center">
        <Paper>
          <Box sx={{ margin: 2 }}>
            <Stack direction="column" spacing={1}>
              <Typography>You have been LOGGED OUT</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Logout;
