import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import ItemCard from "./itemCard";

const SortedInventory = ({ sortedInventory, inventory, items }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {sortedInventory.map((item, index) => {
          const previousItem = sortedInventory[index - 1];
          const showLabel =
            (item.equipped !== "Unequipped" && index === 0) ||
            (item.equipped === "Unequipped" &&
              (!previousItem || previousItem.type !== item.type));

          return (
            <React.Fragment key={item.itemId}>
              {showLabel && (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ width: "100%", textAlign: "left", marginTop: 2 }}
                >
                  {item.equipped !== "Unequipped" ? "Equipped" : item.type}
                </Typography>
              )}
              <ItemCard
                itemId={item.itemId}
                inventory={inventory}
                key={item.itemId}
                items={items}
                canSellItems={false}
              />
            </React.Fragment>
          );
        })}
      </Stack>
    </Box>
  );
};

export default SortedInventory;
