import React from "react";
import { useStore } from "../../zustand";
import Fishing from "../fishing";

const Minigame = () => {
  const controlScheme = useStore((state) => state.controlScheme);
  const cameraIntroduction = useStore((state) => state.cameraIntroduction);

  return (
    <>
      {controlScheme === "fishing" && cameraIntroduction === "none" && (
        <Fishing />
      )}
    </>
  );
};

export default Minigame;
