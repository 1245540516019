import { gameplayStarter } from "../../gameplayStarter";
import { itemStarter } from "../../itemStarter";

export const fishItems = {
  "fa4f96c1-d352-468e-af61-0c965bfd4fac": {
    ...itemStarter,
    name: "Goldfish Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Goldfish meat",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "7e8296b2-636d-4540-b502-84674bdb946d": {
    ...itemStarter,
    name: "Goldfish Scale",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A shiny scale from a goldfish",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "8fb1a48b-4b6c-4e41-80d5-e1e2e27993e2": {
    ...itemStarter,
    name: "Bluegill Meat",
    type: "Cooking",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a bluegill",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "ee643f03-9e5f-40b4-bcd0-765e364b8a8d": {
    ...itemStarter,
    name: "Bluegill Tail",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A tail from a bluegill",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "04915659-d4e2-404a-9a4a-d86120756b00": {
    ...itemStarter,
    name: "Bass Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a bass",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "cd31ece9-98c3-4349-bb90-c3cb056722dc": {
    ...itemStarter,
    name: "Bass Fin",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A fin from a bass",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "48032072-f449-4cdf-8c22-eb5fea3a186b": {
    ...itemStarter,
    name: "Catfish Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a catfish",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "e461554d-22a4-4300-b268-803ad98f1cec": {
    ...itemStarter,
    name: "Catfish Whisker",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A whisker from a catfish",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "0e5c2d53-54fd-4e9a-bd90-5ea4bcfb7a2c": {
    ...itemStarter,
    name: "Perch Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a perch",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "52f0a1f7-e211-4f55-9ffc-233084618df8": {
    ...itemStarter,
    name: "Perch Skeleton",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A skeleton from a perch",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "37a614af-359d-4eb3-a6a0-99db4a2ba9ab": {
    ...itemStarter,
    name: "Sunfish Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a sunfish",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "4e135e92-6dca-415f-894a-57f2fa2ad5ef": {
    ...itemStarter,
    name: "Sunfish Gill",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A gill from a sunfish",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "be359aef-0427-453d-99ca-927e97b474ff": {
    ...itemStarter,
    name: "Trout Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a trout",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "6a375198-4d10-4c0b-bc2a-2c32911f43f0": {
    ...itemStarter,
    name: "Trout Scales",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Scales from a trout",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "efa092be-bad1-4be0-aee4-e033a528ca9a": {
    ...itemStarter,
    name: "Carp Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a carp",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "b670f786-398d-48b3-a38a-0e66f5240a63": {
    ...itemStarter,
    name: "Carp Roe",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Roe from a carp",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "012ae682-ccd3-4509-b550-03f6a0805bd6": {
    ...itemStarter,
    name: "Minnow Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a minnow",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "a11637ab-40c3-4ede-9c0d-c9a4ed82f2e1": {
    ...itemStarter,
    name: "Minnow Fin",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Fin from a minnow",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
  "0e13bfc9-69bf-41bc-a37f-fed74d1c9d5c": {
    ...itemStarter,
    name: "Crappie Meat",
    type: "Cooking",
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Meat from a crappie",
    equipment_slots: [],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      cooking: {
        type: "Raw Food Ingredient",
      },
    },
    stats: null,
  },
  "5ac60671-ba91-4395-9fb7-b0412e35d67e": {
    ...itemStarter,
    name: "Crappie Scale",
    type: "Junk",
    consumable: false,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 10,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Scale from a crappie",
    equipment_slots: [],
    unique: false,
    gameplay: { ...gameplayStarter },
    stats: null,
  },
};
