import { gameplayStarter } from "../../gameplayStarter";
import { itemStarter } from "../../itemStarter";

export const bait = {
  "82eb3dbc-ba3f-4931-b10f-fff02a4f1efa": {
    ...itemStarter,
    name: "Basic Bait",
    type: "Fishing Bait",
    use_placeholder_icon: false,
    consumable: true,
    is_tradable: true,
    is_sellable: true,
    gold_buy_value: 2,
    gold_sell_value: 1,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "Consumed when fishing",
    equipment_slots: ["Fishing Bait"],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      fishing: {
        power: 10,
      },
    },
    stats: {
      "Fishing Power": 10,
    },
  },
};
