import React from "react";
import { useStore } from "../../zustand";
import FishingUI from "../UI/fishing/game";

const MinigameUIManager = () => {
  const controlScheme = useStore((state) => state.controlScheme);
  const cameraIntroduction = useStore((state) => state.cameraIntroduction);

  return (
    <>
      {controlScheme === "fishing" && cameraIntroduction === "none" && (
        <FishingUI />
      )}
    </>
  );
};

export default MinigameUIManager;
