import { fishing } from "./fishing";

export const items = {
  ...fishing,
};

export const starterInventory = JSON.stringify({
  inventory: {
    // Basic Fishing Rod
    "d91f6070-dcab-4521-8c5a-038d3b8e31d5": {
      name: items["d91f6070-dcab-4521-8c5a-038d3b8e31d5"].name,
      count: 1,
      equipped: "Fishing Rod",
      unique: false,
    },
  },
});
