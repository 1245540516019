import React, { useEffect } from "react";
import { useStore } from "./zustand";
import { Router } from "./Router";

const getQueryStringParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramsObject = {};
  for (const [key, value] of params) {
    paramsObject[key] = value;
  }
  return paramsObject;
};

const App = () => {
  const handleQS = React.useCallback(async () => {
    const params = getQueryStringParams(window.location.search);

    useStore.setState({ query: params });
  }, []);

  useEffect(() => {
    handleQS();
  }, [handleQS]);

  useEffect(() => {
    // Disable right-click context menu globally
    const disableContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", disableContextMenu);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  return <Router />;
};

export default App;
