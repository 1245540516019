import React, { useState } from "react";
import { getDocumentViaAttribute } from "../utility/CRUD";
import { getUserAccount } from "../utility/CRUD/session";
import {
  Paper,
  FormControl,
  TextField,
  Button,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { useWebStore } from "../zustand/web";
import { useNavigate } from "react-router-dom";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

const MainLogin = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    password: "",
    errormsg: "none",
  });

  const updateState = (newState) => {
    setState((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  const wipeText = () => {
    updateState({
      email: "",
      password: "",
    });
  };

  const login = async () => {
    const userAccount = await getUserAccount(state.email, state.password);
    wipeText();

    if (userAccount.error) {
      updateState({ errormsg: userAccount.errorData.type });
    } else {
      const querysearch = await getDocumentViaAttribute(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        "user_id",
        userAccount.$id,
      );

      useWebStore.setState({
        userAccount: userAccount,
        userData: querysearch.documents[0],
      });

      navigate("/account/profile");
    }
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="center">
        <Paper>
          <Box sx={{ margin: 2 }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="h6">Metaverse</Typography>
              <FormControl>
                <TextField
                  id="tf-email"
                  label="Email"
                  variant="filled"
                  color="secondary"
                  type="email"
                  value={state.email}
                  onChange={(e) => updateState({ email: e.target.value })}
                  required={true}
                />
                <TextField
                  id="tf-password]"
                  label="Password"
                  variant="filled"
                  type="password"
                  color="secondary"
                  value={state.password}
                  onChange={(e) => updateState({ password: e.target.value })}
                  required={true}
                />

                <Button color="primary" variant="contained" onClick={login}>
                  Login
                </Button>
              </FormControl>
              {state.errormsg !== "none" && (
                <Typography variant="body1">{state.errormsg}</Typography>
              )}
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default MainLogin;
