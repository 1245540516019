import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const LowestBox = styled(Box)(() => ({
  backgroundColor: "#222222",
  height: "100vh",
  width: "100vw",
  overflowY: "auto",
  overflowX: "hidden",
}));

export { LowestBox };
