export const starterProgression = JSON.stringify({
  progression: {
    overall: 0,
    fishing: 0,
    combat: 0,
  },
});

export const xpScaleBase = 100;

export const xpScaleExponent = 2;

export const starterFeats = JSON.stringify({
  feats: {},
});
