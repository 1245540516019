import { useEffect } from "react";
import { useStore } from "../../zustand";
import { useFrame } from "@react-three/fiber";
import { Catch } from "./Cast";
import { calculateQTEPower } from "./helpers";
import { useFishingStore } from "../../zustand/fishing";

import {
  maxQTEDuration,
  durationSuffix,
} from "../../utility/constants/fishing";

const Fishing = () => {
  const canCast = useFishingStore((state) => state.canCast);
  const equipmentCheck = useFishingStore((state) => state.equipmentCheck);
  const isQTEPhase = useFishingStore((state) => state.isQTEPhase);
  const qteTargetDuration = useFishingStore((state) => state.qteTargetDuration);
  const qtePressed = useFishingStore((state) => state.qtePressed);
  const maxQTEPower = useFishingStore((state) => state.maxQTEPower);

  const qteFullDuration = maxQTEDuration + durationSuffix;

  useFrame((_, delta) => {
    if (isQTEPhase) {
      const qteClock = useFishingStore.getState().qteClock;
      const newQTERingClock = qteClock + delta;

      let done = false;

      if (newQTERingClock > qteFullDuration) {
        done = true;
      }

      if (qtePressed) {
        const qteScore = calculateQTEPower(
          qteTargetDuration,
          newQTERingClock,
          maxQTEPower,
        );
        useFishingStore.setState({
          qtePressed: false,
          qteScore: qteScore,
          qteClock: 0,
          isQTEPhase: false,
        });
        Catch();
      } else if (done) {
        useFishingStore.setState({
          qtePressed: false,
          qteScore: 0,
          qteClock: 0,
          isQTEPhase: false,
        });
        Catch();
      } else {
        useFishingStore.setState({ qteClock: newQTERingClock });
      }
    }
  });

  useEffect(() => {
    if (!canCast && equipmentCheck) {
      let willCast = false;

      const hasFishingRodEquipped = useStore
        .getState()
        .checkIfSlotEquipped("Fishing Rod");

      const hasFishingBaitEquipped = useStore
        .getState()
        .checkIfSlotEquipped("Fishing Bait");

      if (hasFishingRodEquipped && hasFishingBaitEquipped) {
        willCast = true;
        // console.log("Both a fishing rod and fishing bait are equipped.");
      } else {
        // console.log("Both a fishing rod and fishing bait are not equipped.");
        willCast = false;
      }

      useFishingStore.setState({ canCast: willCast, equipmentCheck: false });
    }
  }, [canCast, equipmentCheck]);

  return null;
};

export default Fishing;
