import { useRealtimeStore } from "../zustand/realtime";
import { useEffect, useRef, createContext } from "react";
import { io } from "socket.io-client";

import { useStore } from "../zustand";
import { useWebStore } from "../zustand/web";

const SocketContext = createContext(null);

const { REACT_APP_AWS_SERVER_PORT } = process.env;

export const SocketManager = ({ children }) => {
  const setRealtime = useRealtimeStore((state) => state.setRealtime);

  const isLevelChosen = useStore((state) => state.isLevelChosen);
  const levelChosen = useStore((state) => state.levelChosen);
  const isSendChatTime = useStore((state) => state.isSendChatTime);
  const gameChatInput = useStore((state) => state.gameChatInput);

  const serverIP = useStore.getState().serverIP;

  const socket = useRef(null);

  useEffect(() => {
    socket.current = io(`${serverIP}:${REACT_APP_AWS_SERVER_PORT}`);

    function onConnect() {
      console.log("Socket Manager connected");
      useStore.setState({
        socket_id: socket.current.id,
        isSocketConnected: true,
      });
    }

    function onWaitingAreaInvite() {
      console.log("Socket Manager invited client to waiting area");
      const userData = useWebStore.getState().userData;
      const userInfo = {
        user_id: userData.user_id,
        username: userData.username,
      };

      socket.current.emit("waiting_area_join", userInfo);
    }

    function onWaitingAreaSuccess() {
      console.log("Client connected to waiting area");
      useStore.setState({
        isInWaitingArea: true,
      });
    }

    function onLevelSuccess(levelName) {
      console.log("Client connected to level", levelName);
      useStore.setState({
        isGameTenant: true,
        levelName: levelName,
      });
    }

    function onRealtime(realtimeData) {
      setRealtime(realtimeData);
    }

    function onChatBroadcast(value) {
      const date = new Date(value.timestamp);
      const timeString = date.toLocaleTimeString();

      console.log(
        `CHAT | ${timeString} | ${value.username} | ${value.message}`,
      );

      let chat = { ...value };

      chat.timeString = timeString;

      useStore.getState().addToChat_action(chat);
    }

    socket.current.on("connect", onConnect);
    socket.current.on("waiting_area_invite", onWaitingAreaInvite);
    socket.current.on("waiting_area_success", onWaitingAreaSuccess);
    socket.current.on("join_success", onLevelSuccess);
    socket.current.on("chat_broadcast", onChatBroadcast);
    socket.current.on("realtime", onRealtime);

    return () => {
      socket.current.off("connect", onConnect);
      socket.current.off("waiting_area_invite", onWaitingAreaInvite);
      socket.current.off("waiting_area_success", onWaitingAreaSuccess);
      socket.current.on("join_success", onLevelSuccess);
      socket.current.off("chat_broadcast", onChatBroadcast);
      socket.current.on("realtime", onRealtime);
      socket.current.disconnect();
    };
  }, [setRealtime, serverIP]);

  // here
  useEffect(() => {
    if (isLevelChosen) {
      console.log("Client requested to join", levelChosen);
      socket.current.emit("level_join", levelChosen);
      useStore.setState({
        isLevelChosen: false,
        isLevelRequested: true,
        levelChosen: "none",
      });
    }
  }, [isLevelChosen, levelChosen]);

  useEffect(() => {
    if (isSendChatTime) {
      socket.current.emit("chat_send", gameChatInput);

      useStore.setState({
        isSendChatTime: false,
        isGameChatOpen: false,
        gameChatInput: "",
      });
    }
  }, [isSendChatTime, gameChatInput]);

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext };
