import { getDropResult } from "../../getDropResult";

const starter = {
  name: "CHANGE ME",
  minWeight: 0.1,
  maxWeight: 0.5,
  minLength: 0.05,
  maxLength: 0.3,
  rarity: 0.5,
  levelRange: [1, 1],
  baseXP: 10,
  region: ["Hub"],
  basePower: 100,
  drops: {},
  feats: {
    length: 0,
    weight: 0,
  },
};

export const fish = {
  "653f3b54-dafc-49f2-bf14-15fba7abdeff": {
    ...starter,
    name: "Goldfish",
    minWeight: 0.1,
    maxWeight: 0.5,
    minLength: 0.05,
    maxLength: 0.3,
    rarity: 0.5,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "fa4f96c1-d352-468e-af61-0c965bfd4fac": 0.5,
      // Junk
      "7e8296b2-636d-4540-b502-84674bdb946d": 0.5,
    },
  },
  "7996f78f-8602-4531-a778-e5a55b1c4566": {
    ...starter,
    name: "Bluegill",
    minWeight: 0.1,
    maxWeight: 0.6,
    minLength: 0.1,
    maxLength: 0.25,
    rarity: 0.3,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "8fb1a48b-4b6c-4e41-80d5-e1e2e27993e2": 0.5,
      // Junk
      "ee643f03-9e5f-40b4-bcd0-765e364b8a8d": 0.5,
    },
  },
  "9ef24534-dcbe-43e2-8376-6cd926446592": {
    ...starter,
    name: "Bass",
    minWeight: 0.5,
    maxWeight: 3.6,
    minLength: 0.3,
    maxLength: 0.6,
    rarity: 0.2,
    levelRange: [1, 2],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "04915659-d4e2-404a-9a4a-d86120756b00": 0.5,
      // Junk
      "cd31ece9-98c3-4349-bb90-c3cb056722dc": 0.5,
    },
  },
  "ef2e5e1b-5fc7-4016-9fb1-18ba357ddf73": {
    ...starter,
    name: "Catfish",
    minWeight: 0.5,
    maxWeight: 10,
    minLength: 0.2,
    maxLength: 1,
    rarity: 0.1,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "48032072-f449-4cdf-8c22-eb5fea3a186b": 0.5,
      // Junk
      "e461554d-22a4-4300-b268-803ad98f1cec": 0.5,
    },
  },
  "46f9b65b-0f71-47cf-85ad-83acbeb33399": {
    ...starter,
    name: "Perch",
    minWeight: 0.1,
    maxWeight: 1.5,
    minLength: 0.1,
    maxLength: 0.5,
    rarity: 0.4,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "0e5c2d53-54fd-4e9a-bd90-5ea4bcfb7a2c": 0.5,
      // Junk
      "52f0a1f7-e211-4f55-9ffc-233084618df8": 0.5,
    },
  },
  "97b2c5b6-43be-4cf8-8c52-b2783d4aa24e": {
    ...starter,
    name: "Sunfish",
    minWeight: 0.05,
    maxWeight: 0.4,
    minLength: 0.05,
    maxLength: 0.3,
    rarity: 0.6,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "37a614af-359d-4eb3-a6a0-99db4a2ba9ab": 0.5,
      // Junk
      "4e135e92-6dca-415f-894a-57f2fa2ad5ef": 0.5,
    },
  },
  "d091a590-394e-4367-aded-8dda886d066b": {
    ...starter,
    name: "Trout",
    minWeight: 0.2,
    maxWeight: 4,
    minLength: 0.2,
    maxLength: 0.75,
    rarity: 0.2,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "be359aef-0427-453d-99ca-927e97b474ff": 0.5,
      // Junk
      "6a375198-4d10-4c0b-bc2a-2c32911f43f0": 0.5,
    },
  },
  "bc294417-2001-4792-9659-de9bb2ad05c6": {
    ...starter,
    name: "Carp",
    minWeight: 0.5,
    maxWeight: 15,
    minLength: 0.3,
    maxLength: 1.2,
    rarity: 0.15,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "efa092be-bad1-4be0-aee4-e033a528ca9a": 0.5,
      // Junk
      "b670f786-398d-48b3-a38a-0e66f5240a63": 0.5,
    },
  },
  "e49903ea-97db-4873-810d-fe5910bbf932": {
    ...starter,
    name: "Minnow",
    minWeight: 0.01,
    maxWeight: 0.1,
    minLength: 0.02,
    maxLength: 0.1,
    rarity: 0.7,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "012ae682-ccd3-4509-b550-03f6a0805bd6": 0.5,
      // Junk
      "a11637ab-40c3-4ede-9c0d-c9a4ed82f2e1": 0.5,
    },
  },
  "4c005052-3db4-4f04-9181-1612cbd52ac1": {
    ...starter,
    name: "Crappie",
    minWeight: 0.2,
    maxWeight: 2,
    minLength: 0.1,
    maxLength: 0.4,
    rarity: 0.25,
    levelRange: [1, 3],
    baseXP: 10,
    region: ["Hub"],
    basePower: 125,
    drops: {
      // Meat
      "0e13bfc9-69bf-41bc-a37f-fed74d1c9d5c": 0.5,
      // Junk
      "5ac60671-ba91-4395-9fb7-b0412e35d67e": 0.5,
    },
  },
};

export const getRandomFishFromRegion = (region) => {
  // Filter fish by region
  const fishFromRegion = Object.entries(fish).filter(([_, details]) =>
    details.region.includes(region),
  );

  if (fishFromRegion.length === 0) return null;

  // Normalize rarity values
  const totalRarity = fishFromRegion.reduce(
    (sum, [_, details]) => sum + details.rarity,
    0,
  );
  const normalizedRarities = fishFromRegion.map(([uuid, details]) => ({
    uuid,
    ...details,
    normalizedRarity: details.rarity / totalRarity,
  }));

  // Randomly select a fish based on normalized rarities
  const randomValue = Math.random();
  let cumulativeProbability = 0;

  for (let i = 0; i < normalizedRarities.length; i++) {
    cumulativeProbability += normalizedRarities[i].normalizedRarity;
    if (randomValue < cumulativeProbability) {
      const selectedFish = normalizedRarities[i];
      const weight =
        selectedFish.minWeight +
        Math.random() * (selectedFish.maxWeight - selectedFish.minWeight);
      const length =
        selectedFish.minLength +
        Math.random() * (selectedFish.maxLength - selectedFish.minLength);

      // Randomly select a level within the specified range
      const [minLevel, maxLevel] = selectedFish.levelRange;
      const level = Math.floor(
        minLevel + Math.random() * (maxLevel - minLevel + 1),
      );

      selectedFish.level = level;

      // Calculate the power
      const weightRatio =
        (weight - selectedFish.minWeight) /
        (selectedFish.maxWeight - selectedFish.minWeight);
      const lengthRatio =
        (length - selectedFish.minLength) /
        (selectedFish.maxLength - selectedFish.minLength);
      const powerPreLevelAdjustment =
        selectedFish.basePower * (1 + 0.25 * weightRatio + 0.25 * lengthRatio);

      selectedFish.power = powerPreLevelAdjustment * level;

      // Get drop results
      const dropResults = getDropResult(selectedFish.drops, true);

      selectedFish.dropResults = dropResults;

      return {
        ...selectedFish,
      };
    }
  }
};
