import React, { useEffect, useState } from "react";
import { useWebStore } from "../zustand/web";

import {
  Box,
  Container,
  Stack,
  Typography,
  Paper,
  IconButton,
  Divider,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import { caselessCompare } from "../utility/strings";
import { useNavigate } from "react-router-dom";

import { updateDocument, getDocumentViaAttribute } from "../utility/CRUD";

import { UpdateUserData } from "../utility/CRUD/userData";
import { Logout } from "../utility/CRUD/session";
import { devMsg } from "../utility/devMsg";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

const Profile = () => {
  const navigate = useNavigate();
  const userData = useWebStore.getState().userData;
  const userAccount = useWebStore.getState().userAccount;

  const refreshUserData = useWebStore((state) => state.refreshUserData);

  const [justOnce, setJustOnce] = useState(false);

  const [state, setState] = useState({
    // parsedData
    friends: [],
    blockedUsers: [],
    friendRequests: [],
    friendRequestsInbound: [],
    friendRequestsOutbound: [],

    friendRenameInput: "",
    friendRenameIndex: -1,

    isFriendSearch: false,
    friendSearchInput: "",

    // before, success, fail
    friendSearchResult: "before",
    friendSearchResponse: {},

    isBlockSearch: false,

    // before, success, fail
    blockSearchResult: "before",
    blockSearchResponse: {},
    blockSearchInput: "",
  });

  const updateState = (newState) => {
    setState((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  useEffect(() => {
    if (!justOnce || refreshUserData) {
      setJustOnce(true);
      useWebStore.setState({ refreshUserData: false });
      if (userData.friends) {
        const parsedFriends = JSON.parse(userData.friends);
        updateState({ friends: parsedFriends.friends });
      }

      if (userData.blocked_users) {
        const parsedBlocked = JSON.parse(userData.blocked_users);
        updateState({ blockedUsers: parsedBlocked.blocked_users });
      }

      if (userData.friend_requests) {
        const parsedRequests = JSON.parse(userData.friend_requests);
        const friendRequestsInbound = [];
        const friendRequestsOutbound = [];

        for (let i = 0; i < parsedRequests.friend_requests.length; i++) {
          if (parsedRequests.friend_requests[i].type === "inbound") {
            friendRequestsInbound.push(parsedRequests.friend_requests[i]);
          } else {
            friendRequestsOutbound.push(parsedRequests.friend_requests[i]);
          }
        }

        // console.log("inbound outbound ", friendRequestsInbound, friendRequestsOutbound)
        updateState({
          friendRequests: parsedRequests.friend_requests,
          friendRequestsOutbound: friendRequestsOutbound,
          friendRequestsInbound: friendRequestsInbound,
        });
      }
    }
  }, [justOnce, userData, refreshUserData]);

  const editNickname = (key) => {
    updateState({
      friendRenameIndex: key,
      friendRenameInput: state.friends[key].nickname,
    });
  };

  const checkUsernameFriend = async () => {
    const usernameResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "username",
      state.friendSearchInput,
    );

    if (
      usernameResponse.error ||
      caselessCompare(state.friendSearchInput, userData.username)
    ) {
      console.log("CHECK USERNAME FRIENDS ERROR");
      updateState({ friendSearchResult: "fail", friendSearchResponse: {} });
    } else {
      if (usernameResponse.total === 1) {
        console.log("FOUND FRIEND", usernameResponse.documents[0]);
        updateState({
          friendSearchResponse: usernameResponse.documents[0],
          friendSearchResult: "success",
        });
      } else {
        console.log("Issue finding friends");
        updateState({ friendSearchResult: "fail", friendSearchResponse: {} });
      }
    }
  };

  const checkUsernameBlock = async () => {
    if (caselessCompare(userData.username, state.blockSearchInput)) {
      console.log("Issue, user cannot block self");
      updateState({ blockSearchResult: "fail" });
      return;
    }
    const usernameResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "username",
      state.blockSearchInput,
    );

    if (usernameResponse.error) {
      console.log("CHECK USERNAME BLOCK ERROR");
      updateState({ blockSearchResult: "fail" });
    } else {
      if (usernameResponse.total === 1) {
        console.log(
          "FOUND USER TO BLOCK",
          usernameResponse.documents[0].user_id,
        );
        updateState({
          blockSearchResponse: usernameResponse.documents[0],
          blockSearchResult: "success",
        });
      } else {
        console.log("Issue finding user to block");
        updateState({ blockSearchResult: "fail" });
      }
    }
  };

  const addFriendRequest = async () => {
    const outboundBlocked = JSON.parse(userData.blocked_users);
    const outboundFriends = JSON.parse(userData.friends);
    const outboundFriendRequests = JSON.parse(userData.friend_requests);

    const inboundBlocked = JSON.parse(state.friendSearchResponse.blocked_users);
    const inboundFriends = JSON.parse(state.friendSearchResponse.friends);
    const inboundFriendRequests = JSON.parse(
      state.friendSearchResponse.friend_requests,
    );

    let currentDate = new Date();
    let isoDate = currentDate.toISOString();

    let badRequest = false;

    console.log("checking if outbound blocked inbound");
    // check to make sure outbound doesn't have inbound blocked
    for (let i = 0; i < outboundBlocked.blocked_users.length; i++) {
      if (
        caselessCompare(
          outboundBlocked.blocked_users[i].user_id,
          state.friendSearchResponse.user_id,
        )
      ) {
        badRequest = true;
        console.log("FAILED, outbound blocked inbound");
      }
    }

    console.log("checking if outbound already friends with inbound");
    // check to make sure outbound isn't already friends with inbound
    for (let i = 0; i < outboundFriends.friends.length; i++) {
      if (
        caselessCompare(
          outboundFriends.friends[i].user_id,
          state.friendSearchResponse.user_id,
        )
      ) {
        badRequest = true;
        console.log("FAILED, outbound already friends with inbound");
      }
    }

    console.log("checking if outbound already requested inbound");
    // console.log("here3", outboundFriendRequests);
    for (let i = 0; i < outboundFriendRequests.friend_requests.length; i++) {
      if (
        caselessCompare(
          outboundFriendRequests.friend_requests[i].user_id_inbound,
          state.friendSearchResponse.user_id,
        )
      ) {
        badRequest = true;
        console.log("FAILED, outbound already friends with inbound");
      }
    }

    console.log("checking if inbound blocked outbound");
    // check to make sure outbound doesn't have inbound blocked
    for (let i = 0; i < inboundBlocked.blocked_users.length; i++) {
      if (
        caselessCompare(
          inboundBlocked.blocked_users[i].user_id,
          userData.user_id,
        )
      ) {
        badRequest = true;
        console.log("FAILED, inbound blocked outbound");
      }
    }

    console.log("checking if inbound already friends with outbound");
    // check to make sure outbound isn't already friends with inbound
    for (let i = 0; i < inboundFriends.friends.length; i++) {
      if (
        caselessCompare(inboundFriends.friends[i].user_id, userData.user_id)
      ) {
        badRequest = true;
        console.log("FAILED, inbound already friends with outbound");
      }
    }

    console.log("checking if inbound already requested outbound");
    for (let i = 0; i < inboundFriendRequests.friend_requests.length; i++) {
      if (
        caselessCompare(
          inboundFriendRequests.friend_requests[i].user_id_inbound,
          userData.user_id,
        )
      ) {
        badRequest = true;
        console.log("FAILED, inbound already friends with outbound");
      }
    }

    if (badRequest) {
      console.log("IS A BAD REQUEST");
    } else {
      const inboundSetup = {
        type: "inbound",
        username_outbound: userData.username,
        user_id_outbound: userData.user_id,
        username_inbound: state.friendSearchResponse.username,
        user_id_inbound: state.friendSearchResponse.user_id,
        date: isoDate,
      };

      const outboundSetup = {
        type: "outbound",
        username_outbound: userData.username,
        user_id_outbound: userData.user_id,
        username_inbound: state.friendSearchResponse.username,
        user_id_inbound: state.friendSearchResponse.user_id,
        date: isoDate,
      };

      outboundFriendRequests.friend_requests.push(outboundSetup);
      inboundFriendRequests.friend_requests.push(inboundSetup);

      const outboundData = {
        friend_requests: JSON.stringify(outboundFriendRequests),
      };
      const inboundData = {
        friend_requests: JSON.stringify(inboundFriendRequests),
      };

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        userData.$id,
        outboundData,
      );

      console.log("wrote outbound data");

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        state.friendSearchResponse.$id,
        inboundData,
      );

      console.log("wrote inbound data");

      cancelFriendSearch();
      UpdateUserData(userData.user_id);
    }
  };

  const removeFriendRequest = async (otherUserID) => {
    const otherUserResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "user_id",
      otherUserID,
    );

    if (otherUserResponse.total === 1) {
      const otherUserDocID = otherUserResponse.documents[0].$id;
      const otherUserFriendRequests = JSON.parse(
        otherUserResponse.documents[0].friend_requests,
      );
      const localFriendRequests = JSON.parse(userData.friend_requests);

      let otherSplice = -1;

      for (let i = 0; i < otherUserFriendRequests.friend_requests.length; i++) {
        if (
          caselessCompare(
            otherUserFriendRequests.friend_requests[i].user_id_inbound,
            otherUserID,
          ) ||
          caselessCompare(
            otherUserFriendRequests.friend_requests[i].user_id_outbound,
            otherUserID,
          )
        ) {
          otherSplice = i;
        }
      }

      if (otherSplice > -1) {
        otherUserFriendRequests.friend_requests.splice(otherSplice, 1);
      }

      const otherData = {
        friend_requests: JSON.stringify(otherUserFriendRequests),
      };

      let localSplice = -1;

      for (let i = 0; i < localFriendRequests.friend_requests.length; i++) {
        if (
          caselessCompare(
            localFriendRequests.friend_requests[i].user_id_inbound,
            otherUserID,
          ) ||
          caselessCompare(
            localFriendRequests.friend_requests[i].user_id_outbound,
            otherUserID,
          )
        ) {
          localSplice = i;
        }
      }

      if (localSplice > -1) {
        localFriendRequests.friend_requests.splice(localSplice, 1);
      }

      const localData = {
        friend_requests: JSON.stringify(localFriendRequests),
      };

      console.log(localData, otherData);

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        userData.$id,
        localData,
      );

      console.log("wrote local data");

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        otherUserDocID,
        otherData,
      );

      console.log("wrote other data");
    } else {
      console.log("BIG ERROR");
    }

    UpdateUserData(userData.user_id);
  };

  const removeFriend = async (otherUserID) => {
    const otherUserResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "user_id",
      otherUserID,
    );

    if (otherUserResponse.total === 1) {
      const otherUserDocID = otherUserResponse.documents[0].$id;
      const otherUserFriends = JSON.parse(
        otherUserResponse.documents[0].friends,
      );
      const localFriends = JSON.parse(userData.friends);

      let otherSplice = -1;

      for (let i = 0; i < otherUserFriends.friends.length; i++) {
        if (
          caselessCompare(otherUserFriends.friends[i].user_id, userData.user_id)
        ) {
          otherSplice = i;
        }
      }

      if (otherSplice > -1) {
        otherUserFriends.friends.splice(otherSplice, 1);
      }

      const otherData = {
        friends: JSON.stringify(otherUserFriends),
      };

      let localSplice = -1;

      for (let i = 0; i < localFriends.friends.length; i++) {
        if (caselessCompare(localFriends.friends[i].user_id, otherUserID)) {
          localSplice = i;
        }
      }

      if (localSplice > -1) {
        localFriends.friends.splice(localSplice, 1);
      }

      const localData = {
        friends: JSON.stringify(localFriends),
      };

      console.log(localData, otherData);

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        userData.$id,
        localData,
      );

      console.log("wrote local data");

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        otherUserDocID,
        otherData,
      );

      console.log("wrote other data");
    } else {
      console.log("BIG ERROR");
    }

    UpdateUserData(userData.user_id);
  };

  const addFriendByUserID = async (otherUserID) => {
    removeFriendRequest(otherUserID);

    console.log("removed friend request");

    const otherUserResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "user_id",
      otherUserID,
    );

    console.log("fetched other user data");

    if (otherUserResponse.total === 1) {
      const otherUserDocID = otherUserResponse.documents[0].$id;

      const otherUserFriends = JSON.parse(
        otherUserResponse.documents[0].friends,
      );

      const localFriends = JSON.parse(userData.friends);

      let currentDate = new Date();
      let isoDate = currentDate.toISOString();

      otherUserFriends.friends.push({
        nickname: userData.username,
        username: userData.username,
        user_id: userData.user_id,
        date: isoDate,
      });

      localFriends.friends.push({
        nickname: otherUserResponse.documents[0].username,
        username: otherUserResponse.documents[0].username,
        user_id: otherUserID,
        date: isoDate,
      });

      const otherData = {
        friends: JSON.stringify(otherUserFriends),
      };

      const localData = {
        friends: JSON.stringify(localFriends),
      };

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        otherUserDocID,
        otherData,
      );

      console.log("other updated with friend");

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        userData.$id,
        localData,
      );

      console.log("local updated with friend");
    } else {
      console.log("BIG ERROR");
    }
  };

  const blockUser = async () => {
    const blockedUsers = JSON.parse(userData.blocked_users);

    let userNameMatch = false;

    for (let i = 0; i < blockedUsers.blocked_users.length; i++) {
      if (
        caselessCompare(
          state.blockSearchInput,
          blockedUsers.blocked_users[i].username,
        )
      ) {
        userNameMatch = true;
      }
    }

    if (!userNameMatch) {
      blockedUsers.blocked_users.push({
        user_id: state.blockSearchResponse.user_id,
        username: state.blockSearchInput,
      });

      const blockedUsersString = JSON.stringify(blockedUsers);

      const data = { blocked_users: blockedUsersString };

      await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        userData.$id,
        data,
      );

      UpdateUserData(userData.user_id);
      cancelBlockSearch();
    } else {
      console.log("ALREADY BLOCKED");
    }
  };

  const unblockUser = async (key) => {
    const blockedUsers = JSON.parse(userData.blocked_users);

    blockedUsers.blocked_users.splice(key, 1);

    const blockedUsersString = JSON.stringify(blockedUsers);

    const data = { blocked_users: blockedUsersString };

    await updateDocument(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      userData.$id,
      data,
    );

    UpdateUserData(userData.user_id);
  };

  const saveNickname = async (key) => {
    updateState({ friendRenameIndex: -1 });

    const friendsCopy = [...state.friends];

    friendsCopy[key].nickname = state.friendRenameInput;

    const friendsString = JSON.stringify({
      friends: friendsCopy,
    });

    const data = {
      friends: friendsString,
    };

    const response = await updateDocument(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      userData.$id,
      data,
    );

    console.log("Update Response", response);

    updateState({ friends: friendsCopy });
  };

  const cancelFriendSearch = () => {
    updateState({
      isFriendSearch: false,
      friendSearchResult: "before",
      friendSearchInput: "",
      friendSearchResponse: {},
    });
  };

  const cancelBlockSearch = () => {
    updateState({
      isBlockSearch: false,
      blockSearchResult: "before",
      blockSearchInput: "",
      blockSearchUserID: "",
    });
  };

  const logoutClick = async () => {
    const logoutResponse = await Logout(navigate);
    if (logoutResponse === "error") {
      devMsg("ERORR LOGGING OUT");
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        minWidth: "100vw",
        bgcolor: "background.default",
        py: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "#222222",
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h2">Account Page</Typography>
          <Box>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/");
                }}
              >
                Landing
              </Button>
              <Button variant="contained" onClick={logoutClick}>
                Logout
              </Button>
            </Stack>
          </Box>
          <Paper variant="outlined" sx={{ padding: 1 }}>
            <Typography variant="h6">User Info: {userAccount.name}</Typography>
            <Paper variant="outlined" sx={{ padding: 1 }}>
              <Typography variant="body1">
                User ID: {userData.user_id}
              </Typography>
              <Typography variant="body1">
                Username: {userData.username}
              </Typography>
              <Divider />
              <Typography variant="body1">
                Email: {userAccount.email}
              </Typography>
              <Typography variant="body1">
                Email Verified: {String(userAccount.emailVerification)}
              </Typography>
              <Divider />
              <Typography variant="body1">
                Phone: {userAccount.phone}
              </Typography>
              <Typography variant="body1">
                Phone Verified: {String(userAccount.phoneVerification)}
              </Typography>
            </Paper>
          </Paper>

          <Paper variant="outlined">
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6">Friends: </Typography>
              <Paper variant="outlined" sx={{ padding: 1 }}>
                <Stack direction="column">
                  {state.friends.map((prop, key) => {
                    return (
                      <div key={key}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {state.friendRenameIndex !== key && (
                            <Typography variant="body1">
                              {prop.nickname}
                            </Typography>
                          )}

                          {state.friendRenameIndex === key && (
                            <TextField
                              id="tf-nickname"
                              label="Nickname"
                              variant="filled"
                              color="secondary"
                              type="text"
                              value={state.friendRenameInput}
                              onChange={(e) =>
                                updateState({
                                  friendRenameInput: e.target.value,
                                })
                              }
                              required={true}
                            />
                          )}
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            {state.friendRenameIndex !== key && (
                              <Tooltip title="Edit nickname">
                                <IconButton
                                  onClick={() => {
                                    editNickname(key);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            {state.friendRenameIndex !== key && (
                              <Tooltip title="Remove friend">
                                <IconButton
                                  onClick={() => {
                                    removeFriend(prop.user_id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            {state.friendRenameIndex === key && (
                              <Tooltip title="Save nickname">
                                <IconButton
                                  onClick={() => {
                                    saveNickname(key);
                                  }}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </Box>
                        {key !== state.friends.length - 1 && <Divider />}
                      </div>
                    );
                  })}
                  {state.friends.length === 0 && (
                    <Typography variant="body1">None</Typography>
                  )}
                </Stack>
              </Paper>
            </Box>
          </Paper>

          <Paper variant="outlined">
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6">Friend Requests: </Typography>
              <Box>
                <Stack direction="column" spacing={1}>
                  {!state.isFriendSearch && (
                    <Box>
                      <Button
                        variant="contained"
                        disabled={state.isFriendSearch}
                        onClick={() => {
                          updateState({ isFriendSearch: true });
                        }}
                      >
                        Add Friend
                      </Button>
                    </Box>
                  )}

                  {state.isFriendSearch && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        id="tf-friend-search"
                        label="username"
                        variant="filled"
                        color="secondary"
                        type="text"
                        value={state.friendSearchInput}
                        onChange={(e) =>
                          updateState({
                            friendSearchInput: e.target.value,
                            friendSearchResult: "before",
                          })
                        }
                        required={true}
                      />
                      <Tooltip title="Cancel">
                        <IconButton onClick={cancelFriendSearch}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Search user">
                        <span>
                          <IconButton
                            onClick={checkUsernameFriend}
                            disabled={
                              state.friendSearchInput === "" ||
                              state.friendSearchResult === "success"
                            }
                          >
                            <PersonSearchIcon />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title="Add friend">
                        <span>
                          <IconButton
                            onClick={addFriendRequest}
                            disabled={state.friendSearchResult !== "success"}
                          >
                            <PersonAddAlt1Icon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  )}

                  {state.friendRequestsOutbound.length > 0 && (
                    <>
                      <Typography variant="h6">
                        Sent Friend Requests:{" "}
                      </Typography>

                      <Paper variant="outlined" sx={{ padding: 1 }}>
                        <Stack direction="column">
                          {state.friendRequestsOutbound.map((prop, key) => {
                            return (
                              <div key={key}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography>
                                    {prop.username_inbound}
                                  </Typography>

                                  <Stack direction="row" alignItems={"center"}>
                                    <Tooltip title="Cancel request">
                                      <IconButton
                                        onClick={() => {
                                          removeFriendRequest(
                                            prop.user_id_inbound,
                                          );
                                        }}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                </Box>
                                {key !==
                                  state.friendRequestsOutbound.length - 1 && (
                                  <Divider />
                                )}
                              </div>
                            );
                          })}
                        </Stack>
                      </Paper>
                    </>
                  )}

                  {state.friendRequestsInbound.length > 0 && (
                    <>
                      <Typography variant="h6">
                        Incoming Friend Requests:{" "}
                      </Typography>
                      <Paper variant="outlined" sx={{ padding: 1 }}>
                        <Stack direction="column">
                          {state.friendRequestsInbound.map((prop, key) => {
                            return (
                              <div key={key}>
                                {prop.type === "inbound" && (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography>
                                      {prop.username_outbound}
                                    </Typography>
                                    <Stack
                                      direction="row"
                                      alignItems={"center"}
                                    >
                                      <Tooltip title="accept">
                                        <IconButton
                                          onClick={() => {
                                            addFriendByUserID(
                                              prop.user_id_outbound,
                                            );
                                          }}
                                        >
                                          <ThumbUpIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="reject">
                                        <IconButton
                                          onClick={() =>
                                            removeFriendRequest(
                                              prop.user_id_outbound,
                                            )
                                          }
                                        >
                                          <ThumbDownOffAltIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Stack>
                                  </Box>
                                )}
                                {key !==
                                  state.friendRequestsInbound.length - 1 && (
                                  <Divider />
                                )}
                              </div>
                            );
                          })}
                        </Stack>
                      </Paper>
                    </>
                  )}
                </Stack>
              </Box>
            </Box>
          </Paper>

          <Paper variant="outlined">
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6">Blocked Users: </Typography>
              <Box>
                <Stack direction="column" spacing={1}>
                  {!state.isBlockSearch && (
                    <Box>
                      <Button
                        variant="contained"
                        disabled={state.isBlockSearch}
                        onClick={() => {
                          updateState({ isBlockSearch: true });
                        }}
                      >
                        Add User
                      </Button>
                    </Box>
                  )}

                  {state.isBlockSearch && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        id="tf-block-search"
                        label="username"
                        variant="filled"
                        color="secondary"
                        type="text"
                        value={state.blockSearchInput}
                        onChange={(e) =>
                          updateState({ blockSearchInput: e.target.value })
                        }
                        required={true}
                      />

                      <Tooltip title="Cancel">
                        <IconButton onClick={cancelBlockSearch}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Search user">
                        <span>
                          <IconButton
                            onClick={checkUsernameBlock}
                            disabled={
                              state.blockSearchInput === "" ||
                              state.blockSearchResult === "success"
                            }
                          >
                            <PersonSearchIcon />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title="Block user">
                        <span>
                          <IconButton
                            onClick={blockUser}
                            disabled={state.blockSearchResult !== "success"}
                          >
                            <PersonAddAlt1Icon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  )}

                  {state.blockedUsers.length > 0 && (
                    <Paper variant="outlined" sx={{ padding: 1 }}>
                      <Stack direction="column">
                        {state.blockedUsers.map((prop, key) => {
                          return (
                            <div key={key}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography>{prop.username}</Typography>

                                <Stack direction="row" alignItems={"center"}>
                                  <Tooltip title="Unblock user">
                                    <IconButton
                                      onClick={() => {
                                        unblockUser(key);
                                      }}
                                    >
                                      <PersonRemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Box>
                              {key !== state.blockedUsers.length - 1 && (
                                <Divider />
                              )}
                            </div>
                          );
                        })}
                      </Stack>
                    </Paper>
                  )}
                </Stack>
              </Box>
            </Box>
          </Paper>
        </Stack>
      </Box>
    </Container>
  );
};

export default Profile;
