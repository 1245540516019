import { useFrame } from "@react-three/fiber";
import { useStore } from "../../zustand";
import { GamepadDeadzone } from "../../utility/constants/playerConstants";

const HandleController = () => {
  const haveEvents = "GamepadEvent" in window;
  const haveWebkitEvents = "WebKitGamepadEvent" in window;
  const controllers = [];
  const rAF = window.requestAnimationFrame;

  const connecthandler = (e) => {
    addgamepad(e.gamepad);
  };

  const addgamepad = (gamepad) => {
    console.log("added gamepad");

    controllers[gamepad.index] = gamepad;
    if (gamepad.index === 0) {
      let rumbleSupport = "none";
      if (gamepad.vibrationActuator) {
        if (gamepad.vibrationActuator.type === "dual-rumble") {
          // Dual-rumble supported
          rumbleSupport = "dual-rumble";
        } else if (gamepad.vibrationActuator.type === "rumble") {
          // Single rumble supported
          rumbleSupport = "rumble";
        }
      }

      console.log("gamepad", gamepad);

      useStore.setState({
        gamepad: gamepad,
        gamepadRumbleSupport: rumbleSupport,
        isGamepadConnected: true,
        activeControlType: "gamepad-directions",
      });
    }
    rAF(updateStatus);
  };

  const disconnecthandler = (e) => {
    removegamepad(e.gamepad);
  };

  const removegamepad = (gamepad) => {
    delete controllers[gamepad.index];
    if (gamepad.index === 0) {
      useStore.setState({ isGamepadConnected: false });
    }
  };

  const updateStatus = () => {
    scangamepads();
    rAF(updateStatus);
  };

  const scangamepads = () => {
    const gamepads = navigator.getGamepads
      ? navigator.getGamepads()
      : navigator.webkitGetGamepads
        ? navigator.webkitGetGamepads()
        : [];
    for (const gamepad of gamepads) {
      if (gamepad && gamepad.index in controllers) {
        controllers[gamepad.index] = gamepad;
      }
    }
  };

  if (haveEvents) {
    window.addEventListener("gamepadconnected", connecthandler);
    window.addEventListener("gamepaddisconnected", disconnecthandler);
  } else if (haveWebkitEvents) {
    window.addEventListener("webkitgamepadconnected", connecthandler);
    window.addEventListener("webkitgamepaddisconnected", disconnecthandler);
  } else {
    setInterval(scangamepads, 3000);
  }

  useFrame(() => {
    if (controllers.length) {
      const gamepad = controllers[0];

      const frozenAxes = useStore.getState().frozenAxes;
      const gamepadMappings = useStore.getState().gamepadMappings;
      const activeControlType = useStore.getState().activeControlType;

      if (gamepad) {
        const gp = {
          0: Boolean(gamepad.buttons[0]?.pressed),
          1: Boolean(gamepad.buttons[1]?.pressed),
          2: Boolean(gamepad.buttons[2]?.pressed),
          3: Boolean(gamepad.buttons[3]?.pressed),
          4: Boolean(gamepad.buttons[4]?.pressed),
          5: Boolean(gamepad.buttons[5]?.pressed),
          6: Boolean(gamepad.buttons[6]?.pressed),
          7: Boolean(gamepad.buttons[7]?.pressed),
          8: Boolean(gamepad.buttons[8]?.pressed),
          9: Boolean(gamepad.buttons[9]?.pressed),
          10: Boolean(gamepad.buttons[10]?.pressed),
          11: Boolean(gamepad.buttons[11]?.pressed),
          12: Boolean(gamepad.buttons[12]?.pressed),
          13: Boolean(gamepad.buttons[13]?.pressed),
          14: Boolean(gamepad.buttons[14]?.pressed),
          15: Boolean(gamepad.buttons[15]?.pressed),

          v0: gamepad.buttons[0]?.value,
          v1: gamepad.buttons[1]?.value,
          v2: gamepad.buttons[2]?.value,
          v3: gamepad.buttons[3]?.value,
          v4: gamepad.buttons[4]?.value,
          v5: gamepad.buttons[5]?.value,
          v6: gamepad.buttons[6]?.value,
          v7: gamepad.buttons[7]?.value,
          v8: gamepad.buttons[8]?.value,
          v9: gamepad.buttons[9]?.value,
          v10: gamepad.buttons[10]?.value,
          v11: gamepad.buttons[11]?.value,
          v12: gamepad.buttons[12]?.value,
          v13: gamepad.buttons[13]?.value,
          v14: gamepad.buttons[14]?.value,
          v15: gamepad.buttons[15]?.value,

          a0: false,
          a1: false,
          a2: false,
          a3: false,
          a4: false,
          a5: false,
          a6: false,
          a7: false,
        };

        if (frozenAxes?.length) {
          if (frozenAxes[0] === -1) {
            gp.a0 = gamepad.axes[0] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a0 = Math.abs(gamepad.axes[0]) > GamepadDeadzone;
          }

          if (frozenAxes[1] === -1) {
            gp.a1 = gamepad.axes[1] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a1 = Math.abs(gamepad.axes[1]) > GamepadDeadzone;
          }

          if (frozenAxes[2] === -1) {
            gp.a2 = gamepad.axes[2] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a2 = Math.abs(gamepad.axes[2]) > GamepadDeadzone;
          }

          if (frozenAxes[3] === -1) {
            gp.a3 = gamepad.axes[3] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a3 = Math.abs(gamepad.axes[3]) > GamepadDeadzone;
          }

          if (frozenAxes[4] === -1) {
            gp.a4 = gamepad.axes[4] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a4 = Math.abs(gamepad.axes[4]) > GamepadDeadzone;
          }

          if (frozenAxes[5] === -1) {
            gp.a5 = gamepad.axes[5] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a5 = Math.abs(gamepad.axes[5]) > GamepadDeadzone;
          }

          if (frozenAxes[6] === -1) {
            gp.a6 = gamepad.axes[6] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a6 = Math.abs(gamepad.axes[6]) > GamepadDeadzone;
          }

          if (frozenAxes[7] === -1) {
            gp.a7 = gamepad.axes[7] > -1 + GamepadDeadzone * 2;
          } else {
            gp.a7 = Math.abs(gamepad.axes[7]) > GamepadDeadzone;
          }
        }

        let hitStick = false;
        let hitDirection = false;

        if (
          gp[gamepadMappings["up"]] ||
          gp[gamepadMappings["down"]] ||
          gp[gamepadMappings["left"]] ||
          gp[gamepadMappings["right"]]
        ) {
          hitDirection = true;
        }

        if (
          gp[gamepadMappings["ls_up"]] > GamepadDeadzone ||
          gp[gamepadMappings["ls_left"]] > GamepadDeadzone ||
          gp[gamepadMappings["rs_up"]] > GamepadDeadzone ||
          gp[gamepadMappings["rs_left"]] > GamepadDeadzone
        ) {
          hitStick = true;
        }

        if (hitStick) {
          useStore.setState({ activeControlType: "gamepad-stick" });
        } else if (hitDirection) {
          useStore.setState({ activeControlType: "gamepad-directions" });
        } else if (
          gp[0] ||
          gp[1] ||
          gp[2] ||
          gp[3] ||
          gp[4] ||
          gp[5] ||
          gp[6] ||
          gp[7] ||
          gp[8] ||
          gp[9] ||
          gp[10] ||
          gp[11] ||
          gp[12] ||
          gp[13] ||
          gp[14] ||
          gp[15]
        ) {
          useStore.setState({ activeControlType: "gamepad-stick" });
        } else if (
          activeControlType !== "keyboard-directions" &&
          activeControlType !== "touch-stick" &&
          activeControlType !== "click-location"
        ) {
          useStore.setState({ activeControlType: "none" });
        }

        let lastButtonPressed = "none";
        const setLastButtonPressed = (val) => {
          lastButtonPressed = val;
        };

        gp.a0 && setLastButtonPressed("a0");
        gp.a1 && setLastButtonPressed("a1");
        gp.a2 && setLastButtonPressed("a2");
        gp.a3 && setLastButtonPressed("a3");
        gp.a4 && setLastButtonPressed("a4");
        gp.a5 && setLastButtonPressed("a5");
        gp.a6 && setLastButtonPressed("a6");
        gp.a7 && setLastButtonPressed("a7");

        gp[0] && setLastButtonPressed("0");
        gp[1] && setLastButtonPressed("1");
        gp[2] && setLastButtonPressed("2");
        gp[3] && setLastButtonPressed("3");
        gp[4] && setLastButtonPressed("4");
        gp[5] && setLastButtonPressed("5");
        gp[6] && setLastButtonPressed("6");
        gp[7] && setLastButtonPressed("7");
        gp[8] && setLastButtonPressed("8");
        gp[9] && setLastButtonPressed("9");
        gp[10] && setLastButtonPressed("10");
        gp[11] && setLastButtonPressed("11");
        gp[12] && setLastButtonPressed("12");
        gp[13] && setLastButtonPressed("13");
        gp[14] && setLastButtonPressed("14");
        gp[15] && setLastButtonPressed("15");

        if (lastButtonPressed !== "none") {
          useStore.setState({
            lastGamepadButton: lastButtonPressed,
          });
        }

        useStore.setState({
          gamepadData: {
            0: gp[0],
            1: gp[1],
            2: gp[2],
            3: gp[3],
            4: gp[4],
            5: gp[5],
            6: gp[6],
            7: gp[7],
            8: gp[8],
            9: gp[9],
            10: gp[10],
            11: gp[11],
            12: gp[12],
            13: gp[13],
            14: gp[14],
            15: gp[15],

            v0: gp.v0,
            v1: gp.v1,
            v2: gp.v2,
            v3: gp.v3,
            v4: gp.v4,
            v5: gp.v5,
            v6: gp.v6,
            v7: gp.v7,
            v8: gp.v8,
            v9: gp.v9,
            v10: gp.v10,
            v11: gp.v11,
            v12: gp.v12,
            v13: gp.v13,
            v14: gp.v14,
            v15: gp.v15,

            a0: gamepad.axes[0],
            a1: gamepad.axes[1],
            a2: gamepad.axes[2],
            a3: gamepad.axes[3],
            a4: gamepad.axes[4],
            a5: gamepad.axes[5],
            a6: gamepad.axes[6],
            a7: gamepad.axes[7],
          },
        });
      }
    }
  });

  return null;
};

export default HandleController;
