import React, { useRef, useContext, useEffect, useState } from "react";
import { Vector3, Group } from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { Paper, Typography } from "@mui/material";

import { SocketContext } from "../SocketManager";
import { useStore } from "../../zustand";

import { round3 } from "../../utility/conversion";

import {
  DefaultMoveSpeed,
  ClickLocationHelperFadeDuration,
  GamepadDeadzone,
  TouchDeadzone,
} from "../../utility/constants/playerConstants";

import { calculateMTV, checkForCollisions } from "./playerHelpers";

import combinedData from "../hub";

export function Player({ colliders, ...props }) {
  const socket = useContext(SocketContext);

  const camera = useThree((state) => state.camera);

  // START REFS
  const group = useRef();

  const clickHelperRef = useRef();

  const collider = useRef(new Group());
  // END REFS

  // START ZUSTAND
  const activeControlType = useStore((state) => state.activeControlType);

  const firstMove = useStore((state) => state.firstMove);

  const isKeyboardDirectionsForward = useStore(
    (state) => state.isKeyboardDirectionsForward,
  );
  const isKeyboardDirectionsBackward = useStore(
    (state) => state.isKeyboardDirectionsBackward,
  );
  const isKeyboardDirectionsLeft = useStore(
    (state) => state.isKeyboardDirectionsLeft,
  );
  const isKeyboardDirectionsRight = useStore(
    (state) => state.isKeyboardDirectionsRight,
  );

  const gamepadData = useStore((state) => state.gamepadData);

  const tenantMovementTarget = useStore((state) => state.tenantMovementTarget);

  const clickHelperFade = useStore((state) => state.clickHelperFade);

  const controlScheme = useStore((state) => state.controlScheme);

  const gamepadMappings = useStore((state) => state.gamepadMappings);

  const clickHelperBCTexture = useStore.getState().clickHelperBCTexture;

  const clickHelperOpacityTexture =
    useStore.getState().clickHelperOpacityTexture;

  const touchStickPosition = useStore((state) => state.touchStickPosition);

  const teleportPosition = useStore((state) => state.teleportPosition);

  const isTeleportTime = useStore((state) => state.isTeleportTime);

  const teleportControlScheme = useStore(
    (state) => state.teleportControlScheme,
  );
  // END ZUSTAND

  // START REACT STATE
  const [clickHelperOpacity, setClickHelperOpacity] = useState(0);
  const [clickClock, setClickClock] = useState();
  const [moveForwardy, setMoveForwardy] = useState(false);
  const [moveBackwardy, setMoveBackwardy] = useState(false);
  const [moveLefty, setMoveLefty] = useState(false);
  const [moveRighty, setMoveRighty] = useState(false);
  // END REACT STATE

  // START CONSTANTS
  const targetOpacity = 1;
  const lerpSpeed = targetOpacity / (ClickLocationHelperFadeDuration / 1000); // lerp speed per second
  // END CONSTANTS

  // START FRAME LOOP
  useFrame((_, delta) => {
    let shouldUpdate = false;
    const moveDirection = new Vector3();

    if (controlScheme.includes("move")) {
      if (firstMove) {
        setClickClock(0);
        useStore.setState({ firstMove: false });
      }

      if (activeControlType === "click-location") {
        shouldUpdate = true;
        if (group.current.position.distanceTo(tenantMovementTarget) > 0.1) {
          const direction = group.current.position
            .clone()
            .sub(tenantMovementTarget)
            .normalize()
            .multiplyScalar(DefaultMoveSpeed * delta);

          group.current.position.sub(direction);
          const collisionObject = checkForCollisions(
            collider.current,
            colliders,
          );
          if (
            collisionObject?.collider &&
            collisionObject?.type === "obstacle" &&
            !firstMove
          ) {
            const mtv = calculateMTV(
              collider.current,
              collisionObject.collider,
            );
            group.current.position.add(mtv.multiplyScalar(0.5));

            const newTime = clickClock + delta;
            setClickClock(newTime);

            if (clickClock > 3) {
              useStore.setState({ activeControlType: "none" });
              setClickClock(0);
            }
          } else {
            setClickClock(0);
          }

          if (collisionObject?.collider && collisionObject?.type === "event") {
            const eventIndex = Number(
              String(collisionObject?.collider.name).substring(
                String(collisionObject?.collider.name).lastIndexOf("_") + 1,
              ),
            );
            // console.log("EVENT", eventIndex);
            combinedData[eventIndex].command();
          }

          group.current.lookAt(tenantMovementTarget);
          collider.current.lookAt(tenantMovementTarget);
        } else {
          useStore.setState({ activeControlType: "none" });
        }
      } else if (
        activeControlType === "keyboard-directions" ||
        activeControlType === "gamepad-directions"
      ) {
        shouldUpdate = true;
        const cameraDirection = new Vector3();
        camera.getWorldDirection(cameraDirection);

        const moveForward = new Vector3(
          cameraDirection.x,
          0,
          cameraDirection.z,
        );
        const moveRight = new Vector3(-cameraDirection.z, 0, cameraDirection.x);

        if (moveForwardy) moveDirection.add(moveForward);
        if (moveBackwardy) moveDirection.sub(moveForward);
        if (moveLefty) moveDirection.sub(moveRight);
        if (moveRighty) moveDirection.add(moveRight);

        if (moveDirection.length() > 0) {
          moveDirection.normalize().multiplyScalar(DefaultMoveSpeed * delta);
          group.current.position.add(moveDirection);
          const collisionObject = checkForCollisions(
            collider.current,
            colliders,
          );

          if (
            collisionObject?.collider &&
            collisionObject?.type === "obstacle"
          ) {
            const mtv = calculateMTV(
              collider.current,
              collisionObject.collider,
            );
            group.current.position.add(mtv.multiplyScalar(0.5));

            useStore.setState({ activeControlType: "none" });
          } else {
            const lookAtTarget = group.current.position
              .clone()
              .add(moveDirection);
            group.current.lookAt(lookAtTarget);
            collider.current.lookAt(lookAtTarget);
          }

          if (collisionObject?.collider && collisionObject?.type === "event") {
            const eventIndex = Number(
              String(collisionObject?.collider.name).substring(
                String(collisionObject?.collider.name).lastIndexOf("_") + 1,
              ),
            );
            combinedData[eventIndex].command();
          }
        }
      } else if (activeControlType === "gamepad-stick") {
        const stickX = gamepadData[gamepadMappings["ls_left"]];
        const stickY = gamepadData[gamepadMappings["ls_up"]];

        if (
          Math.abs(stickX) > GamepadDeadzone ||
          Math.abs(stickY) > GamepadDeadzone
        ) {
          shouldUpdate = true;
          const cameraDirection = new Vector3();
          camera.getWorldDirection(cameraDirection);

          const moveForward = new Vector3(
            cameraDirection.x,
            0,
            cameraDirection.z,
          );

          const moveRight = new Vector3(
            -cameraDirection.z,
            0,
            cameraDirection.x,
          );

          moveDirection.set(0, 0, 0);
          if (stickY > 0) {
            moveDirection.sub(moveForward.clone().multiplyScalar(stickY));
          } else {
            moveDirection.add(moveForward.clone().multiplyScalar(-stickY));
          }

          if (stickX > 0) {
            moveDirection.add(moveRight.clone().multiplyScalar(stickX));
          } else {
            moveDirection.sub(moveRight.clone().multiplyScalar(-stickX));
          }

          moveDirection.normalize().multiplyScalar(DefaultMoveSpeed * delta);
          group.current.position.add(moveDirection);

          const collisionObject = checkForCollisions(
            collider.current,
            colliders,
          );

          if (
            collisionObject?.collider &&
            collisionObject?.type === "obstacle"
          ) {
            const mtv = calculateMTV(
              collider.current,
              collisionObject.collider,
            );
            group.current.position.add(mtv.multiplyScalar(0.5));

            // useStore.setState({ activeControlType: "none" });
          } else {
            const lookAtTarget = group.current.position
              .clone()
              .add(moveDirection);
            group.current.lookAt(lookAtTarget);
            collider.current.lookAt(lookAtTarget);
          }

          if (collisionObject?.collider && collisionObject?.type === "event") {
            const eventIndex = Number(
              String(collisionObject?.collider.name).substring(
                String(collisionObject?.collider.name).lastIndexOf("_") + 1,
              ),
            );
            // console.log("EVENT", eventIndex);
            combinedData[eventIndex].command();
          }
        }
      } else if (activeControlType === "touch-stick") {
        const stickX = touchStickPosition.x;
        const stickY = touchStickPosition.y;

        if (
          Math.abs(stickX) > TouchDeadzone ||
          Math.abs(stickY) > TouchDeadzone
        ) {
          shouldUpdate = true;
          const cameraDirection = new Vector3();
          camera.getWorldDirection(cameraDirection);

          const moveForward = new Vector3(
            cameraDirection.x,
            0,
            cameraDirection.z,
          );

          const moveRight = new Vector3(
            -cameraDirection.z,
            0,
            cameraDirection.x,
          );

          moveDirection.set(0, 0, 0);
          if (stickY > 0) {
            moveDirection.sub(moveForward.clone().multiplyScalar(stickY));
          } else {
            moveDirection.add(moveForward.clone().multiplyScalar(-stickY));
          }

          if (stickX > 0) {
            moveDirection.add(moveRight.clone().multiplyScalar(stickX));
          } else {
            moveDirection.sub(moveRight.clone().multiplyScalar(-stickX));
          }

          moveDirection.normalize().multiplyScalar(DefaultMoveSpeed * delta);
          group.current.position.add(moveDirection);

          const collisionObject = checkForCollisions(
            collider.current,
            colliders,
          );

          if (
            collisionObject?.collider &&
            collisionObject?.type === "obstacle"
          ) {
            const mtv = calculateMTV(
              collider.current,
              collisionObject.collider,
            );
            group.current.position.add(mtv.multiplyScalar(0.5));

            // useStore.setState({ activeControlType: "none" });
          } else {
            const lookAtTarget = group.current.position
              .clone()
              .add(moveDirection);
            group.current.lookAt(lookAtTarget);
            collider.current.lookAt(lookAtTarget);
          }
          if (collisionObject?.collider && collisionObject?.type === "event") {
            const eventIndex = Number(
              String(collisionObject?.collider.name).substring(
                String(collisionObject?.collider.name).lastIndexOf("_") + 1,
              ),
            );
            combinedData[eventIndex].command();
          }
        }
      }

      if (clickHelperFade === "startFadeIn") {
        setClickHelperOpacity(0);
        if (clickHelperRef.current) {
          clickHelperRef.current.opacity = 0;
        }
        useStore.setState({ clickHelperFade: "fadeIn" });
      }

      if (clickHelperFade === "fadeIn" && clickHelperOpacity < targetOpacity) {
        const newOpacity = Math.min(
          clickHelperOpacity + lerpSpeed * delta,
          targetOpacity,
        );
        setClickHelperOpacity(newOpacity);
        if (clickHelperRef.current) {
          clickHelperRef.current.opacity = newOpacity;
        }

        if (newOpacity === 1) {
          useStore.setState({ clickHelperFade: "none" });
        }
      }
    }

    if (isTeleportTime) {
      shouldUpdate = true;

      moveDirection.set(
        teleportPosition.x,
        teleportPosition.y,
        teleportPosition.z,
      );

      group.current.position.lerp(moveDirection, 1);
      useStore.setState({
        controlScheme: teleportControlScheme,
        teleportControlScheme: "none",
        isTeleportTime: false,
      });
    }

    if (shouldUpdate) {
      socket.emit(
        "move_tenant",
        props.levelName,
        [
          round3(group.current.position.x),
          round3(group.current.position.y),
          round3(group.current.position.z),
        ],
        [
          round3(group.current.rotation.x),
          round3(group.current.rotation.y),
          round3(group.current.rotation.z),
        ],
      );

      useStore.setState({
        characterPosition: new Vector3(
          group.current.position.x,
          group.current.position.y,
          group.current.position.z,
        ),
      });
    }
  });
  // END FRAME LOOP

  // START USEEFFECTS
  useEffect(() => {
    if (activeControlType === "keyboard-directions") {
      setMoveForwardy(isKeyboardDirectionsForward);
      setMoveBackwardy(isKeyboardDirectionsBackward);
      setMoveLefty(isKeyboardDirectionsLeft);
      setMoveRighty(isKeyboardDirectionsRight);
    }

    if (activeControlType === "gamepad-directions") {
      if (gamepadMappings["up"]) {
        if (String(gamepadMappings["up"]).includes("a")) {
          if (gamepadData[gamepadMappings["up"]] < 0) {
            setMoveForwardy(true);
            setMoveBackwardy(false);
          } else if (gamepadData[gamepadMappings["up"]] > 0) {
            setMoveForwardy(false);
            setMoveBackwardy(true);
          } else {
            setMoveForwardy(false);
            setMoveBackwardy(false);
          }

          if (gamepadData[gamepadMappings["left"]] > 0) {
            setMoveRighty(true);
            setMoveLefty(false);
          } else if (gamepadData[gamepadMappings["left"]] < 0) {
            setMoveRighty(false);
            setMoveLefty(true);
          } else {
            setMoveRighty(false);
            setMoveLefty(false);
          }
        } else {
          setMoveForwardy(gamepadData[gamepadMappings["up"]]);
          setMoveBackwardy(gamepadData[gamepadMappings["down"]]);
          setMoveLefty(gamepadData[gamepadMappings["left"]]);
          setMoveRighty(gamepadData[gamepadMappings["right"]]);
        }
      }
    }
  }, [
    isKeyboardDirectionsBackward,
    isKeyboardDirectionsForward,
    isKeyboardDirectionsLeft,
    isKeyboardDirectionsRight,
    activeControlType,
    gamepadData,
    gamepadMappings,
  ]);

  useEffect(() => {
    const currentCollider = collider.current;
    const capturedColliders = colliders.current; // Capture current colliders value
    capturedColliders.push(currentCollider); // Use captured value

    // Cleanup function using the captured value
    return () => {
      const index = capturedColliders.indexOf(currentCollider);
      if (index > -1) {
        capturedColliders.splice(index, 1);
      }
    };
  }, [colliders]);
  // END USEEFFECTS

  return (
    <>
      <group ref={group}>
        <group ref={collider}>
          <mesh visible={false} position={[0, 1.26, 0]}>
            <boxGeometry args={[1.1, 2.52, 1.1]} />
            <meshStandardMaterial
              transparent={true}
              opacity={0.5}
              color="black"
            />
          </mesh>
        </group>
        <group position={[0, 2.5, 0]}>
          <Html zIndexRange={[2, 1]}>
            <Paper>
              <Typography>{props.username}</Typography>
            </Paper>
          </Html>
        </group>
        <mesh position={[0, 0.5, 0]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={props.bottomColor} />
        </mesh>
        <mesh position={[0, 1.5, 0]}>
          <boxGeometry args={[0.75, 1, 0.75]} />
          <meshStandardMaterial color={props.topColor} />
        </mesh>
        <mesh position={[0, 2.25, 0]}>
          <boxGeometry args={[0.5, 0.5, 0.5]} />
          <meshStandardMaterial color={props.hairColor} />
        </mesh>
      </group>

      {/* Mouse Click Preview Helper */}
      {activeControlType === "click-location" && (
        <mesh
          position={tenantMovementTarget}
          castShadow={false}
          rotation={[Math.PI / -2, 0, 0]}
        >
          <planeGeometry arg={[1, 1, 1]} />
          <meshStandardMaterial
            color="white"
            map={clickHelperBCTexture}
            alphaMap={clickHelperOpacityTexture}
            opacity={0}
            transparent={true}
            ref={clickHelperRef}
          />
        </mesh>
      )}
    </>
  );
}
