import { Vector3 } from "three";
import { create } from "zustand";
import { items } from "../utility/constants/items";
import { updateDocument } from "../utility/CRUD";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

export const useStore = create((set, get) => {
  return {
    // START GAME CONNECTION
    isSocketConnected: false,

    isInWaitingArea: false,

    isLevelRequested: false,

    isLevelChosen: false,

    levelChosen: "none",

    levelName: "none",

    isGameTenant: false,

    socket_id: null,

    serverIP: "http://localhost",

    resetGameState_action: () => {
      set({
        isSocketConnected: false,
        isInWaitingArea: false,
        isLevelChosen: false,
        isLevelRequested: false,
        levelChosen: "none",
        socket_id: null,
        isGameTenant: false,
        levelName: "none",
        gameChatInput: "",
        gameChats: [],
        isGameChatOpen: false,
        isSendChatTime: false,
        gameChatAlarm: false,
      });
    },
    // END GAME CONNECTION

    // START GAME ART
    clickHelperBCTexture: {},
    clickHelperOpacityTexture: {},
    clickHelperFade: "none",
    // END GAME ART

    // START GAME CHAT
    gameChatInput: "",
    gameChats: [],
    isGameChatOpen: false,

    // outbound
    isSendChatTime: false,

    // inbound
    gameChatAlarm: false,

    closeGameChat_action: () => {
      set({
        isGameChatOpen: false,
        gameChatInput: "",
      });
    },

    openGameChat_action: () => {
      set({
        isGameChatOpen: true,
        gameChatInput: "",
      });
    },

    toggleGameChat_action: () => {
      const isGameChatOpen = get().isGameChatOpen;
      const gameChatInput = get().gameChatInput;

      if (!isGameChatOpen) {
        set({ isGameChatOpen: true });
      } else {
        if (gameChatInput === "") {
          set({ isGameChatOpen: false });
        } else {
          console.log("SHOULD SEND CHAT");
          set({ isSendChatTime: true });
        }
      }
    },

    addToChat_action: (newChat) => {
      const gameChats = get().gameChats;

      gameChats.push(newChat);

      set({
        gameChats: gameChats,
        gameChatAlarm: true,
      });
    },
    // END GAME CHAT

    // START GAME SETUP
    // like "afterDT"
    pregameDone: false,
    // END GAME SETUP

    // START UI
    showInventoryModal: false,
    showFishingShopModal: false,
    windowLock: false,
    // END UI

    // START PROGRESSION
    progression: {
      overall: 0,
      fishing: 0,
      combat: 0,
    },
    // END PROGRESSION

    // START INVENTORY
    inventory: {},
    gems: -1,
    gold: -1,
    inventoryDocID: "",

    addMultipleToInventory: async (
      itemsToAdd,
      username,
      everythingIsFree = false,
    ) => {
      const inventory = get().inventory;
      const gold = get().gold;
      const gems = get().gems;
      const inventoryDocID = get().inventoryDocID;
      const timestamp = new Date().getTime();
      const date = new Date(timestamp);
      const timeString = date.toLocaleTimeString();

      const changes = {
        inventory: { ...inventory },
        gold,
        gems,
      };

      let costChat = "";

      itemsToAdd.forEach(({ itemId, count }) => {
        let goldCost = -1;
        let gemsCost = -1;

        if (items[itemId].gold_buy_value !== null) {
          goldCost = items[itemId].gold_buy_value * count;
        }

        if (items[itemId].gems_buy_value !== null) {
          gemsCost = items[itemId].gems_buy_value * count;
        }

        costChat += ` ${count} ${items[itemId].name}`;

        if (everythingIsFree) {
          goldCost = 0;
          gemsCost = 0;
        }

        if (goldCost > 0) {
          costChat += ` for ${goldCost} gold.`;
        } else if (gemsCost > 0) {
          costChat += ` for ${gemsCost} gems.`;
        }

        if (inventory[itemId]) {
          inventory[itemId].count += count;
        } else {
          inventory[itemId] = {
            name: items[itemId].name,
            count: count,
            equipped: "Unequipped",
            unique: items[itemId].unique,
          };
        }
      });

      const canDoIt = itemsToAdd.every(({ itemId, count }) => {
        let canAdd = true;
        let goldCost =
          items[itemId].gold_buy_value !== null
            ? items[itemId].gold_buy_value * count
            : 0;
        let gemsCost =
          items[itemId].gems_buy_value !== null
            ? items[itemId].gems_buy_value * count
            : 0;

        if (everythingIsFree) {
          goldCost = 0;
          gemsCost = 0;
        }

        if (goldCost > 0 && gold < goldCost) {
          canAdd = false;
        } else if (gemsCost > 0 && gems < gemsCost) {
          canAdd = false;
        }

        return canAdd;
      });

      if (canDoIt) {
        itemsToAdd.forEach(({ itemId, count }) => {
          let goldCost =
            items[itemId].gold_buy_value !== null
              ? items[itemId].gold_buy_value * count
              : 0;
          let gemsCost =
            items[itemId].gems_buy_value !== null
              ? items[itemId].gems_buy_value * count
              : 0;

          if (everythingIsFree) {
            goldCost = 0;
            gemsCost = 0;
          }

          changes.gold -= goldCost;
          changes.gems -= gemsCost;
        });

        const theThing = {
          inventory: inventory,
        };

        changes.inventory = JSON.stringify(theThing);

        const response = await updateDocument(
          REACT_APP_DATABASE_ID,
          REACT_APP_IGLOO_DATA_ID,
          inventoryDocID,
          changes,
        );

        if (response?.username) {
          set({
            inventory: inventory,
            gold: changes.gold,
            gems: changes.gems,
          });

          const chat = {
            username: "Server",
            timestamp: timestamp,
            timeString: timeString,
            message: `${username} received:${costChat}`,
          };

          get().addToChat_action(chat);
          return true;
        } else {
          console.log("ERROR COULDN'T CONNECT FOR ITEM");
          return false;
        }
      } else {
        console.log("ERROR can't afford");
        return false;
      }
    },

    toggleEquipStatus: async (itemId, shouldEquip, slot) => {
      const inventory = get().inventory;
      const inventoryDocID = get().inventoryDocID;
      const timestamp = new Date().getTime();
      const date = new Date(timestamp);
      const timeString = date.toLocaleTimeString();

      // Ensure item exists in inventory
      if (!inventory[itemId]) {
        console.log("ERROR: Item not found in inventory");
        return false;
      }

      const itemType = items[itemId].type;

      if (shouldEquip) {
        // Unequip any items of the same type
        // TODO: is this bugged when you weapon a fishing rod?
        for (const id in inventory) {
          if (inventory[id].equipped === itemType) {
            inventory[id].equipped = "Unequipped";
          }
        }

        // Equip the new item
        inventory[itemId].equipped = slot;
      } else {
        // Unequip the item
        inventory[itemId].equipped = "Unequipped";
      }

      const theThing = {
        inventory: inventory,
      };

      const theThingString = JSON.stringify(theThing);

      const data = {
        inventory: theThingString,
      };

      const response = await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        inventoryDocID,
        data,
      );

      if (response?.username) {
        set({
          inventory: inventory,
        });

        const chat = {
          username: "Server",
          timestamp: timestamp,
          timeString: timeString,
          message: `${items[itemId].name} has been ${
            shouldEquip ? "equipped" : "unequipped"
          }.`,
        };

        get().addToChat_action(chat);
        return true;
      } else {
        console.log("ERROR: Couldn't connect to update item status");
        return false;
      }
    },

    destroyMultipleItems: async (itemsToDestroy, shouldGainWealth) => {
      const inventory = get().inventory;
      const gold = get().gold;
      const gems = get().gems;
      const inventoryDocID = get().inventoryDocID;
      const timestamp = new Date().getTime();
      const date = new Date(timestamp);
      const timeString = date.toLocaleTimeString();

      // Initialize changes object
      const changes = {
        inventory: { ...inventory },
        gold,
        gems,
      };

      let destructionChat = "";

      for (const { itemId, count } of itemsToDestroy) {
        // Ensure item exists in inventory
        if (!inventory[itemId]) {
          console.log(`ERROR: Item ${itemId} not found in inventory`);
          return false;
        }

        // Ensure there are enough items to destroy
        if (inventory[itemId].count < count) {
          console.log(`ERROR: Not enough items to destroy for ${itemId}`);
          return false;
        }

        destructionChat += ` ${count} ${items[itemId].name}`;

        // Calculate wealth gain if applicable
        if (shouldGainWealth) {
          const goldGain = items[itemId].gold_sell_value
            ? items[itemId].gold_sell_value * count
            : 0;
          const gemsGain = items[itemId].gems_sell_value
            ? items[itemId].gems_sell_value * count
            : 0;

          changes.gold += goldGain;
          changes.gems += gemsGain;

          if (goldGain > 0) {
            destructionChat += ` and gained ${goldGain} gold`;
          } else if (gemsGain > 0) {
            destructionChat += ` and gained ${gemsGain} gems`;
          }
        }

        // Decrement or remove the item from the inventory
        if (inventory[itemId].count > count) {
          inventory[itemId].count -= count;
        } else {
          delete inventory[itemId];
        }
      }

      const theThing = {
        inventory: inventory,
      };

      const theThingString = JSON.stringify(theThing);

      const data = {
        inventory: theThingString,
        gold: changes.gold,
        gems: changes.gems,
      };

      const response = await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        inventoryDocID,
        data,
      );

      if (response?.username) {
        set({
          inventory: inventory,
          gold: changes.gold,
          gems: changes.gems,
        });

        const chat = {
          username: "Server",
          timestamp: timestamp,
          timeString: timeString,
          message: `Destroyed ${destructionChat}.`,
        };

        get().addToChat_action(chat);
        return true;
      } else {
        console.log("ERROR: Couldn't connect to update inventory");
        return false;
      }
    },

    sellAllJunkItems: async () => {
      const inventory = get().inventory;
      const inventoryDocID = get().inventoryDocID;
      const timestamp = new Date().getTime();
      const date = new Date(timestamp);
      const timeString = date.toLocaleTimeString();

      let totalGold = get().gold;
      let totalGems = get().gems;
      let soldItems = [];

      for (const itemId in inventory) {
        console.log("HERE", itemId);
        if (inventory.hasOwnProperty(itemId)) {
          const item = items[itemId];

          // Check if item type is "Junk"
          if (item.type === "Junk") {
            const count = inventory[itemId].count;
            const goldValue = item.gold_sell_value || 0;
            const gemsValue = item.gems_sell_value || 0;

            const goldGain = goldValue * count;
            const gemsGain = gemsValue * count;

            totalGold += goldGain;
            totalGems += gemsGain;

            // Remove junk item from inventory
            delete inventory[itemId];
            soldItems.push(`${count} ${item.name}`);
          }
        }
      }

      const data = {
        inventory: JSON.stringify(inventory),
        gold: totalGold,
        gems: totalGems,
      };

      // Update database or wherever you store your inventory
      const response = await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        inventoryDocID,
        data,
      );

      if (response?.username) {
        set({
          inventory: inventory,
          gold: totalGold,
          gems: totalGems,
        });

        // Add chat message or log
        const chat = {
          username: "Server",
          timestamp: timestamp,
          timeString: timeString,
          message: `Sold all junk items: ${soldItems.join(", ")}.`,
        };

        get().addToChat_action(chat);
        return true;
      } else {
        console.log("ERROR: Couldn't connect to update inventory");
        return false;
      }
    },

    getIDofEquippedItemSlot: (itemSlot) => {
      const inventory = get().inventory;

      // Iterate through the inventory to find an equipped item of the specified slot
      for (const id in inventory) {
        if (inventory[id].equipped === itemSlot) {
          return id;
        }
      }

      // If no equipped item of the specified slot is found, return false
      return false;
    },

    checkIfSlotEquipped: (itemSlot) => {
      const inventory = get().inventory;

      const hasItemEquipped = Object.keys(inventory).some(
        (key) => inventory[key].equipped === itemSlot,
      );

      return hasItemEquipped;
    },

    // END INVENTORY

    // START HIGH LEVEL CONTROLS
    // touch-stick, gamepad-stick, gamepad-directions, keyboard-directions
    // click-location
    // none
    activeControlType: "none",
    firstMove: false,

    // for outside-in movement
    tenantMovementTarget: new Vector3(),

    characterPosition: new Vector3(),

    // move, chat, fishing, none
    controlScheme: "move",

    // none, fishing
    cameraIntroduction: "none",

    teleportPosition: new Vector3(),

    isTeleportTime: false,

    teleportControlScheme: "none",

    teleportAction: (direction, scheme) => {
      console.log("TELEPORTING!", direction, "THEN", scheme);
      set({
        teleportPosition: direction,
        activeControlType: "none",
        teleportControlScheme: scheme,
        controlScheme: "none",
        isTeleportTime: true,
      });
    },
    // END HIGH LEVEL CONTROLS

    // START GAMEPAD
    isGamepadConnected: false,
    gamepadRumbleSupport: "none",
    gamepadData: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
      14: false,
      15: false,
      v0: undefined,
      v1: undefined,
      v2: undefined,
      v3: undefined,
      v4: undefined,
      v5: undefined,
      v6: undefined,
      v7: undefined,
      v8: undefined,
      v9: undefined,
      v10: undefined,
      v11: undefined,
      v12: undefined,
      v13: undefined,
      v14: undefined,
      v15: undefined,
      a0: undefined,
      a1: undefined,
      a2: undefined,
      a3: undefined,
      a4: undefined,
      a5: undefined,
      a6: undefined,
      a7: undefined,
    },

    lastGamepadButton: "none",

    gamepadMappings: {},

    gamepad: {},

    frozenAxes: [],

    triggerHapticFeedback: () => {
      const gamepadRumbleSupport = get().gamepadRumbleSupport;
      if (gamepadRumbleSupport !== "none") {
        const gamepad = get().gamepad;
        gamepad.vibrationActuator.playEffect("dual-rumble", {
          startDelay: 0,
          duration: 200, // Duration in milliseconds
          weakMagnitude: 0.5, // Intensity of the weak (low-frequency) rumble motor
          strongMagnitude: 0.9, // Intensity of the strong (high-frequency) rumble motor
        });
      }
    },

    triggerHapticFeedbackCombo: () => {
      const gamepadRumbleSupport = get().gamepadRumbleSupport;
      if (gamepadRumbleSupport !== "none") {
        const gamepad = get().gamepad;

        const sampleRate = 20; // Define sample rate in milliseconds

        const rumbleSequence = [
          { weakMagnitude: 0.9, strongMagnitude: 0, duration: 250 },
          { type: "pause", duration: 250 },
          { weakMagnitude: 0.9, strongMagnitude: 0, duration: 250 },
          { type: "pause", duration: 250 },
          { weakMagnitude: 0, strongMagnitude: 0.9, duration: 250 },
          { type: "pause", duration: 250 },
          { weakMagnitude: 0, strongMagnitude: 0.9, duration: 250 },
          { type: "pause", duration: 250 }, // Adding a pause at the end
          {
            type: "lerp",
            startWeak: 0,
            endWeak: 0.95,
            startStrong: 0,
            endStrong: 0,
            duration: 500,
          },
          {
            type: "lerp",
            startWeak: 0.95,
            endWeak: 0,
            startStrong: 0,
            endStrong: 0,
            duration: 2000,
          },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0, strongMagnitude: 0.25, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0, strongMagnitude: 0.5, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0, strongMagnitude: 0.75, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0, strongMagnitude: 0.95, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0.25, strongMagnitude: 0, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0.5, strongMagnitude: 0, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0.75, strongMagnitude: 0, duration: 250 },
          { type: "pause", duration: 500 },
          { weakMagnitude: 0.95, strongMagnitude: 0, duration: 250 },
        ];

        let delay = 0;

        rumbleSequence.forEach((rumble) => {
          if (rumble.type === "pause") {
            delay += rumble.duration; // Just increase the delay for pauses
          } else if (rumble.type === "lerp") {
            const steps = Math.ceil(rumble.duration / sampleRate);
            const stepDuration = rumble.duration / steps;
            for (let i = 0; i <= steps; i++) {
              setTimeout(
                () => {
                  const t = i / steps;
                  const weakMagnitude =
                    rumble.startWeak + t * (rumble.endWeak - rumble.startWeak);
                  const strongMagnitude =
                    rumble.startStrong +
                    t * (rumble.endStrong - rumble.startStrong);
                  gamepad.vibrationActuator.playEffect("dual-rumble", {
                    startDelay: 0,
                    duration: stepDuration,
                    weakMagnitude,
                    strongMagnitude,
                  });
                },
                delay + i * stepDuration,
              );
            }
            delay += rumble.duration; // Increase delay by the duration of the lerp
          } else {
            setTimeout(() => {
              gamepad.vibrationActuator.playEffect("dual-rumble", {
                startDelay: 0,
                duration: rumble.duration,
                weakMagnitude: rumble.weakMagnitude,
                strongMagnitude: rumble.strongMagnitude,
              });
            }, delay);

            delay += rumble.duration; // Increase delay by the duration of the current rumble
          }
        });
      }
    },
    // END GAMEPAD

    // START KEYBOARD CONTROLS
    isKeyboardDirectionsForward: false,
    isKeyboardDirectionsBackward: false,
    isKeyboardDirectionsLeft: false,
    isKeyboardDirectionsRight: false,
    // END KEYBOARD CONTROLS

    // START TOUCH CONTROLS
    touchStickPosition: { x: 0, y: 0 },
    setTouchStickPosition: (x, y) => set({ touchStickPosition: { x, y } }),
    // END TOUCH CONTROLS
  };
});
