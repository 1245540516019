import { create } from "zustand";
import { updateDocument } from "../utility/CRUD";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

export const useProgressionStore = create((set, get) => {
  return {
    overallXP: 0,
    fishingXP: 0,
    combatXP: 0,

    updateProgression: async (docID) => {
      const overall = get().overallXP;
      const fishing = get().fishingXP;
      const combat = get().combatXP;

      const progressionObject = {};

      progressionObject.progression = {
        overall,
        fishing,
        combat,
      };

      const data = { progression: JSON.stringify(progressionObject) };

      const response = await updateDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        docID,
        data,
      );

      return response;
    },
  };
});
