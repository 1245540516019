import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Box, OrbitControls } from "@react-three/drei";
import { Paper } from "@mui/material";

const CustomCollisionDetection = () => {
  const [boxes, setBoxes] = useState([]);

  // Custom collision detection function using AABB (Axis-Aligned Bounding Box)
  const checkCollisions = () => {
    // Iterate through all boxes
    for (let i = 0; i < boxes.length; i++) {
      const box1 = boxes[i];
      for (let j = i + 1; j < boxes.length; j++) {
        const box2 = boxes[j];

        // Calculate AABB for both boxes
        const box1Min = [
          box1.position[0] - box1.dimensions[0] / 2,
          box1.position[1] - box1.dimensions[1] / 2,
          box1.position[2] - box1.dimensions[2] / 2,
        ];
        const box1Max = [
          box1.position[0] + box1.dimensions[0] / 2,
          box1.position[1] + box1.dimensions[1] / 2,
          box1.position[2] + box1.dimensions[2] / 2,
        ];

        const box2Min = [
          box2.position[0] - box2.dimensions[0] / 2,
          box2.position[1] - box2.dimensions[1] / 2,
          box2.position[2] - box2.dimensions[2] / 2,
        ];
        const box2Max = [
          box2.position[0] + box2.dimensions[0] / 2,
          box2.position[1] + box2.dimensions[1] / 2,
          box2.position[2] + box2.dimensions[2] / 2,
        ];

        // Perform AABB collision detection
        if (
          box1Min[0] < box2Max[0] &&
          box1Max[0] > box2Min[0] &&
          box1Min[1] < box2Max[1] &&
          box1Max[1] > box2Min[1] &&
          box1Min[2] < box2Max[2] &&
          box1Max[2] > box2Min[2]
        ) {
          console.log(
            `Collision detected between Box ${box1.id} and Box ${box2.id}`,
          );
        }
      }
    }
  };

  // Function to add a new box with randomized position, scale, and dimensions
  const addBox = () => {
    const newBox = {
      id: boxes.length,
      position: [
        Math.random() * 10 - 5, // Random x position within range [-5, 5]
        Math.random() * 4, // Random y position within range [0, 4]
        Math.random() * 10 - 5, // Random z position within range [-5, 5]
      ],
      dimensions: [
        Math.random() * 2 + 1, // Random width between 1 and 3
        Math.random() * 2 + 1, // Random height between 1 and 3
        Math.random() * 2 + 1, // Random depth between 1 and 3
      ],
    };
    setBoxes((prevBoxes) => [...prevBoxes, newBox]);
    checkCollisions();
  };

  return (
    <Paper sx={{ width: "90vw", height: "90vh" }}>
      <button onClick={addBox}>Add Box</button>
      <Canvas>
        {/* Render all boxes */}
        {boxes.map((box) => (
          <Box
            key={box.id}
            position={box.position}
            args={box.dimensions}
            onClick={checkCollisions}
          >
            <meshStandardMaterial color="blue" />
          </Box>
        ))}

        {/* Floor */}
        <mesh position={[0, -1, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry attach="geometry" args={[10, 10]} />
          <meshStandardMaterial color="gray" />
        </mesh>

        {/* Lighting */}
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 5, 5]} intensity={0.5} />
        <OrbitControls />
      </Canvas>
    </Paper>
  );
};

export default CustomCollisionDetection;
