export const calculateQTEPower = (duration, userTime, maxQTEPower) => {
  const timeDifference = Math.abs(
    Math.max(duration, userTime) - Math.min(duration, userTime),
  );

  const percentageDifference = timeDifference / duration;

  let newQTEPower = 0;

  if (percentageDifference < 1) {
    newQTEPower = maxQTEPower * (1 - percentageDifference);
  }

  return newQTEPower;
};
