// import React from "react";
import { useStore } from "../../zustand";
import { useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import { poolLocation } from "../../utility/constants/hubConstants";

export const HandleFishingIntroduction = () => {
  const camera = useThree((state) => state.camera);

  const newPosition = new Vector3(10, 3, 10);

  function areNumbersClose(a, b, c, x, y, z, tolerance = 0.01) {
    const isClose = (num1, num2) => Math.abs(num1 - num2) <= tolerance;

    return isClose(a, x) && isClose(b, y) && isClose(c, z);
  }

  useFrame(() => {
    camera.position.lerp(newPosition, 0.05);
    camera.lookAt(poolLocation);

    if (
      areNumbersClose(
        camera.position.x,
        camera.position.y,
        camera.position.z,
        newPosition.x,
        newPosition.y,
        newPosition.z,
      )
    ) {
      useStore.setState({
        activeControlType: "none",
        controlScheme: "fishing",
        cameraIntroduction: "none",
      });
    }
  });

  return null;
};
