import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { deleteSession } from "../utility/CRUD/session";
import { useStore } from "../zustand";
import { useWebStore } from "../zustand/web";

const { REACT_APP_AWS_SERVER_IP } = process.env;

const Landing = () => {
  const navigate = useNavigate();

  const loggedIn = useWebStore((state) => state.loggedIn);

  const logout = async () => {
    const logoutResponse = await deleteSession();
    console.log("logout response:", logoutResponse);
    if (!logoutResponse.error) {
      navigate("/login/logout");
    }
    useWebStore.setState({ loggedIn: false });
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        minWidth: "100vw",
        bgcolor: "background.default",
        py: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "#222222",
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h2">Iglooverse</Typography>
          <Stack spacing={1} direction="row">
            <Button
              onClick={() => {
                navigate("/helloworld");
              }}
              size="large"
              variant="contained"
            >
              Hello World
            </Button>
            <Button
              onClick={() => {
                navigate("/vik128");
              }}
              size="large"
              variant="contained"
            >
              Vik 128
            </Button>
            <Button
              onClick={() => {
                navigate("/login/welcome");
              }}
              size="large"
              variant="contained"
              disabled={loggedIn}
            >
              Login
            </Button>
            <Button
              onClick={() => {
                navigate("/game/play");
              }}
              size="large"
              variant="contained"
              disabled={!loggedIn}
            >
              {"Play (localhost)"}
            </Button>
            <Button
              onClick={() => {
                useStore.setState({ serverIP: REACT_APP_AWS_SERVER_IP });
                navigate("/game/play");
              }}
              size="large"
              variant="contained"
              disabled={!loggedIn}
            >
              {"Play (AWS)"}
            </Button>
            <Button
              onClick={() => {
                navigate("/account/profile");
              }}
              size="large"
              variant="contained"
              disabled={!loggedIn}
            >
              Account Page
            </Button>
            <Button variant="contained" onClick={logout} disabled={!loggedIn}>
              Logout
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default Landing;
