// hub.js

import { useStore } from "../zustand";
import {
  fishingShopLocation,
  poolLocation,
} from "../utility/constants/hubConstants";
import { useFishingStore } from "../zustand/fishing";

const combinedData = [
  {
    type: "obstacle",
    obstacle: {
      position: [2, 0.5, 2],
      dimensions: [1, 1, 1],
      rotation: [0, 0, 0],
      visible: true,
    },
    glb: null,
    command: null,
  },
  {
    type: "obstacle",
    obstacle: {
      position: [-3, 0.25, 3],
      dimensions: [0.5, 0.5, 2],
      rotation: [0, Math.PI, 0],
      visible: true,
    },
    glb: null,
    command: null,
  },
  {
    type: "event",
    obstacle: {
      position: [poolLocation.x, poolLocation.y + 1, poolLocation.z],
      dimensions: [2, 2, 2],
      rotation: [0, Math.PI, 0],
      visible: false,
    },
    glb: null,
    command: () => {
      useStore.setState({
        activeControlType: "none",
        controlScheme: "none",
        cameraIntroduction: "fishing",
        windowLock: true,
      });
      useFishingStore.setState({
        canCast: false,
        equipmentCheck: true,
      });
    },
  },

  {
    type: "event",
    obstacle: {
      position: [
        fishingShopLocation.x,
        fishingShopLocation.y + 1,
        fishingShopLocation.z,
      ],
      dimensions: [2, 2, 2],
      rotation: [0, Math.PI, 0],
      visible: false,
    },
    glb: null,
    command: () => {
      useStore.setState({
        showFishingShopModal: true,
        controlScheme: "none",
        windowLock: true,
      });
    },
  },

  {
    type: "glb-obstacle",
    obstacle: {
      position: [poolLocation.x, poolLocation.y, poolLocation.z],
      dimensions: [1, 2, 1],
      rotation: [0, 0, 0],
      visible: false,
    },
    glb: {
      filename: "Rubber_Pool_01.glb",
      path: require("../assets/glb/draco/tropicalCity/Rubber_Pool_01.glb"),
      position: [poolLocation.x, poolLocation.y, poolLocation.z],
      rotation: [0, 0, 0],
    },
    command: null,
  },

  // Obstacles with associated GLB
  {
    type: "glb-obstacle",
    obstacle: {
      position: [0, 0.5, -10],
      dimensions: [6.1, 1, 6.1],
      rotation: [0, 0, 0],
      visible: false,
    },
    glb: {
      filename: "SmallHouse_01.glb",
      path: require("../assets/glb/draco/tropicalCity/SmallHouse_01.glb"),
      position: [0, 0, -10],
      rotation: [0, 0, 0],
    },
    command: null,
  },
  {
    type: "obstacle",
    obstacle: {
      position: [-7.25, 0.5, -10],
      dimensions: [1, 1, 4],
      rotation: [0, 0, 0],
      visible: false,
    },
    glb: null,
    command: null,
  },
  {
    type: "glb-obstacle",
    obstacle: {
      position: [-12.75, 0.5, -10],
      dimensions: [1, 1, 4],
      rotation: [0, 0, 0],
      visible: false,
    },
    glb: {
      filename: "Gas_Station_01.glb",
      path: require("../assets/glb/draco/tropicalCity/Gas_Station_01.glb"),
      position: [-10, 0, -10],
      rotation: [0, -Math.PI / 2, 0],
    },
    command: null,
  },
  {
    type: "glb-obstacle",
    obstacle: {
      position: [-21, 0.25, -10],
      dimensions: [8, 0.5, 8],
      rotation: [0, -0.5, 0],
      visible: false,
    },
    glb: {
      filename: "Pizza_01.glb",
      path: require("../assets/glb/draco/tropicalCity/Pizza_01.glb"),
      position: [-21, 0, -10],
      rotation: [0, Math.PI / 3, 0],
    },
    command: null,
  },
  {
    type: "glb-set",
    obstacle: null,
    glb: {
      filename: "Burgers_01.glb",
      path: require("../assets/glb/draco/tropicalCity/Burgers_01.glb"),
      position: [10, 0, -10],
      rotation: [0, 0, 0],
    },
    command: null,
  },
  {
    type: "glb-set",
    obstacle: null,
    glb: {
      filename: "Hotel_01.glb",
      path: require("../assets/glb/draco/tropicalCity/Hotel_01.glb"),
      position: [20, 0, -10],
      rotation: [0, 0, 0],
    },
    command: null,
  },
];

export default combinedData;
