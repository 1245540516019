import { gameplayStarter } from "./gameplayStarter";

export const itemStarter = {
  name: "CHANGE ME",
  type: "CHANGE ME",
  use_placeholder_icon: true,
  consumable: false,
  is_tradable: false,
  is_sellable: false,
  gold_buy_value: 1,
  gold_sell_value: 1,
  gems_buy_value: null,
  gems_sell_value: null,
  description: "CHANGE ME",
  equipment_slots: null,
  unique: false,
  gameplay: {
    ...gameplayStarter,
  },
  stats: null,
};
