import React from "react";
import { useStore } from "../../zustand";
import { HandleCamera } from "./moveCamera";
import { HandleIntroductions } from "./handleIntroductions";
import { HandleFishingCamera } from "./fishingCamera";

const CameraManager = () => {
  const controlScheme = useStore((state) => state.controlScheme);
  const cameraIntroduction = useStore((state) => state.cameraIntroduction);

  return (
    <>
      {cameraIntroduction === "none" && controlScheme === "move" && (
        <HandleCamera />
      )}
      {cameraIntroduction === "none" && controlScheme === "fishing" && (
        <HandleFishingCamera />
      )}
      {cameraIntroduction !== "none" && (
        <HandleIntroductions cameraIntroduction={cameraIntroduction} />
      )}
    </>
  );
};

export default CameraManager;
