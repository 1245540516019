import { Vector3 } from "three";
import {
  GamepadDeadzone,
  GamepadCameraRotationSpeedX,
  GamepadCameraRotationSpeedY,
} from "../../utility/constants/playerConstants";

export const HandleGamepadCamera = (
  gamepadData,
  gamepadMappings,
  distance,
  maxDistance,
  minDistance,
  setDistance,
  ything,
  xthing,
  setYthing,
  setXthing,
  camera,
  target,
) => {
  const normalize = (x) => {
    // First, add 1 to the value
    var y = (x + 1) / 2;
    return y;
  };

  let xAxis = xthing;
  let yAxis = ything;

  if (Math.abs(gamepadData[gamepadMappings["rs_left"]]) > GamepadDeadzone) {
    xAxis -= gamepadData[gamepadMappings["rs_left"]];
  }

  if (Math.abs(gamepadData[gamepadMappings["rs_up"]]) > GamepadDeadzone) {
    yAxis += gamepadData[gamepadMappings["rs_up"]];
  }

  if (String(gamepadMappings["lt"]).includes("v")) {
    if (gamepadData[gamepadMappings["lt"]] > GamepadDeadzone) {
      if (distance < maxDistance) {
        setDistance(distance + gamepadData[gamepadMappings["lt"]]);
      }
    }
  }

  if (String(gamepadMappings["rt"]).includes("v")) {
    if (gamepadData[gamepadMappings["rt"]] > GamepadDeadzone) {
      if (distance > minDistance) {
        setDistance(distance - gamepadData[gamepadMappings["rt"]]);
      }
    }
  }

  if (String(gamepadMappings["lt"]).includes("a")) {
    if (gamepadData[gamepadMappings["lt"]] > -1 + GamepadDeadzone * 2) {
      if (distance < maxDistance) {
        if (gamepadData[gamepadMappings["lt"]] !== 0) {
          setDistance(distance + normalize(gamepadData[gamepadMappings["lt"]]));
        }
      }
    }
  }

  if (String(gamepadMappings["rt"]).includes("a")) {
    if (gamepadData[gamepadMappings["rt"]] > -1 + GamepadDeadzone * 2) {
      if (distance > minDistance) {
        if (gamepadData[gamepadMappings["rt"]] !== 0) {
          setDistance(distance - normalize(gamepadData[gamepadMappings["rt"]]));
        }
      }
    }
  }

  const angleX = xAxis * GamepadCameraRotationSpeedX; // Adjust rotation speed
  const angleY = yAxis * GamepadCameraRotationSpeedY;

  const newPosition = new Vector3(
    target.x + distance * Math.cos(angleY) * Math.sin(angleX),
    target.y + distance * Math.sin(angleY),
    target.z + distance * Math.cos(angleY) * Math.cos(angleX),
  );

  camera.position.lerp(newPosition, 0.1);
  camera.lookAt(target);

  setXthing(xAxis);
  setYthing(yAxis);

  return null;
};
