import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { gamepadSteps } from "./gamepadSteps";

import { useGesture } from "@use-gesture/react";

import { useStore } from "../../zustand";
import { useWebStore } from "../../zustand/web";
import { SocketContext } from "../SocketManager";

import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";

// none
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// keyboard-directions
import KeyboardIcon from "@mui/icons-material/Keyboard";

// gamepad-directions
import GamepadIcon from "@mui/icons-material/Gamepad";

// gamepad-stick
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

// touch-stick
import SwipeIcon from "@mui/icons-material/Swipe";

// touch-location
import TouchAppIcon from "@mui/icons-material/TouchApp";

import PhishingIcon from "@mui/icons-material/Phishing";

import ControlCameraIcon from "@mui/icons-material/ControlCamera";

// click-location
import MouseIcon from "@mui/icons-material/Mouse";
import Modal from "@mui/material/Modal";
import InventoryScreen from "./inventory";
import FishingShop from "./fishing/shop";
import MinigameUIManager from "../minigame/UIManager";
import { Vector3 } from "three";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "90vh",
  overflowX: "hidden",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UIManager = () => {
  const navigate = useNavigate();

  const scrollRef = useRef();

  const socket = useContext(SocketContext);

  const isGameTenant = useStore((state) => state.isGameTenant);
  const isGameChatOpen = useStore((state) => state.isGameChatOpen);
  const gameChatInput = useStore((state) => state.gameChatInput);
  const gameChatAlarm = useStore((state) => state.gameChatAlarm);
  const gameChats = useStore((state) => state.gameChats);
  const isGamepadConnected = useStore((state) => state.isGamepadConnected);
  const gamepadRumbleSupport = useStore((state) => state.gamepadRumbleSupport);
  const showFishingShopModal = useStore((state) => state.showFishingShopModal);

  const controlScheme = useStore((state) => state.controlScheme);

  const showInventoryModal = useStore((state) => state.showInventoryModal);

  const setTouchStickPosition = useStore(
    (state) => state.setTouchStickPosition,
  );

  const isTouchSupported = useWebStore((state) => state.isTouchSupported);

  const lastGamepadButton = useStore((state) => state.lastGamepadButton);

  const activeControlType = useStore((state) => state.activeControlType);

  const gamepadMappings = useStore((state) => state.gamepadMappings);

  const [localGameChats, setLocalGameChats] = useState(gameChats);

  const [showGamepadBindingModal, setShowGamepadBindingModal] = useState(false);

  const [gamepadStyle, setGamepadStyle] = useState("none");

  const handleGamepadStyle = (val) => {
    setGamepadStyle(val);
    setStep(1);
  };

  const [innerPosition, setInnerPosition] = useState({ x: 0, y: 0 });

  const bind = useGesture({
    onDrag: ({ movement: [deltaX, deltaY], memo }) => {
      if (!memo) {
        memo = {
          startX: innerPosition.x,
          startY: innerPosition.y,
        };
      }

      // Calculate new position
      let newX = Math.min(Math.max(memo.startX + deltaX, -40), 40);
      let newY = Math.min(Math.max(memo.startY + deltaY, -40), 40);

      setInnerPosition({ x: newX, y: newY });

      // Normalize deltaX and deltaY for backend purposes
      const normalizedDeltaX = Math.min(deltaX / 100, 1);
      const normalizedDeltaY = Math.min(deltaY / 100, 1);

      setTouchStickPosition(normalizedDeltaX, normalizedDeltaY);
      useStore.setState({ activeControlType: "touch-stick" });

      return memo;
    },
    onDragEnd: () => {
      setInnerPosition({ x: 0, y: 0 }); // Reset to center
      useStore.setState({ activeControlType: "none" });
    },
  });

  const handleGameFreeze = () => {
    const gamepads = navigator.getGamepads
      ? navigator.getGamepads()
      : navigator.webkitGetGamepads
        ? navigator.webkitGetGamepads()
        : [];

    useStore.setState({
      frozenAxes: gamepads[0].axes,
      lastGamepadButton: "none",
    });

    setStep(2);
  };

  const handleOpenGamepadBindingModal = () => {
    setShowGamepadBindingModal(true);
    useStore.setState({ controlScheme: "chat" });
  };

  const handleCloseGamepadBindingModal = () => {
    setShowGamepadBindingModal(false);
    setStep(0);
    useStore.setState({ controlScheme: "move" });
  };

  const handleCloseInventoryModal = () => {
    useStore.setState({
      controlScheme: "move",
      showInventoryModal: false,
      windowLock: false,
    });
  };

  const handleCloseFishingShopModal = () => {
    useStore.setState({ showFishingShopModal: false, windowLock: false });
    const teleportPosition = new Vector3(-2, 0, 0);
    useStore.getState().teleportAction(teleportPosition, "move");
  };

  const handleGamepadSave = () => {
    const frozenAxes = useStore.getState().frozenAxes;

    useStore.setState({ gamepadMappings: mappings });

    console.log("Button mappings saved:", mappings);

    localStorage.setItem("gamepadMappings", JSON.stringify(mappings));

    localStorage.setItem("frozenAxes", JSON.stringify(frozenAxes));

    handleCloseGamepadBindingModal();
  };

  const [step, setStep] = useState(0);

  const [mappings, setMappings] = useState({});

  const handleButtonPress = (buttonIndex) => {
    const currentStep = gamepadSteps[step].map;
    setMappings((prev) => ({ ...prev, [currentStep]: buttonIndex }));

    if (gamepadSteps[step].map === "lt") {
      console.log(buttonIndex);
      if (!String(buttonIndex).includes("a")) {
        setMappings((prev) => ({ ...prev, [currentStep]: `v${buttonIndex}` }));
      }
    }

    if (gamepadSteps[step].map === "rt") {
      console.log(buttonIndex);
      if (!String(buttonIndex).includes("a")) {
        setMappings((prev) => ({ ...prev, [currentStep]: `v${buttonIndex}` }));
      }
    }

    useStore.setState({ lastGamepadButton: "none" });

    if (step < gamepadSteps.length) {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (gameChatAlarm) {
      useStore.setState({ gameChatAlarm: false });
      setLocalGameChats(gameChats);

      if (isGameChatOpen) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [gameChatAlarm, gameChats, isGameChatOpen]);

  useEffect(() => {
    if (isGameChatOpen) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isGameChatOpen]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "0.5em",
          left: "0.5em",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(8px)",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack direction="row" alignItems="center" margin={1} spacing={1}>
            <Typography variant="h6">Dev Overlay:</Typography>
            <Button
              disabled={!isGameTenant}
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                socket.emit("level_disconnect");
                useStore.getState().resetGameState_action();
                navigate("/");
              }}
            >
              Disconnect
            </Button>

            <Divider orientation="vertical" flexItem />

            <Typography>
              Gamepad: {isGamepadConnected && "Connected"}
              {!isGamepadConnected && "Not Connected"}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography>Rumble: {gamepadRumbleSupport}</Typography>

            <Divider orientation="vertical" flexItem />

            <Typography>
              Has Mappings: {gamepadMappings["a"] && "YES"}
              {!gamepadMappings["a"] && "NO"}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Button
              disabled={!isGamepadConnected}
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                useStore.getState().triggerHapticFeedback();
              }}
            >
              Basic Rumble
            </Button>
            <Button
              disabled={!isGamepadConnected}
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                useStore.getState().triggerHapticFeedbackCombo();
              }}
            >
              Combo Rumble
            </Button>
            <Button
              disabled={!isGamepadConnected}
              variant="outlined"
              size="small"
              color="error"
              onClick={handleOpenGamepadBindingModal}
            >
              Edit Mapping
            </Button>
          </Stack>
        </Paper>
      </Box>

      {isGameChatOpen && (
        <Box
          sx={{
            position: "absolute",
            bottom: "0.5em",
            left: "0.5em",
          }}
        >
          <Paper
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              backdropFilter: "blur(8px)",
              boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Stack direction="column" alignItems="left" margin={1}>
              <Stack
                direction="column"
                sx={{ maxHeight: "12em", overflowY: "auto" }}
                ref={scrollRef}
                margin={1}
              >
                {localGameChats.map((chat, key) => (
                  <Stack
                    direction="row"
                    key={key}
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography variant="subtitle2">
                      {chat.timeString}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>{chat.username}</b>
                    </Typography>
                    <Typography>{chat.message}</Typography>
                  </Stack>
                ))}
              </Stack>

              <Stack direction="row" alignItems="center" margin={0.5}>
                <IconButton
                  onClick={() => {
                    useStore.getState().closeGameChat_action();
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <TextField
                  id="tf-chatbar"
                  variant="outlined"
                  color="secondary"
                  type="text"
                  value={gameChatInput}
                  onChange={(e) =>
                    useStore.setState({
                      gameChatInput: e.target.value,
                    })
                  }
                  required={true}
                  autoFocus={true}
                />
              </Stack>
            </Stack>
          </Paper>
        </Box>
      )}

      {!isGameChatOpen && (
        <Box
          sx={{
            position: "absolute",
            bottom: "0.5em",
            left: "0.5em",
          }}
        >
          <Paper
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              backdropFilter: "blur(8px)",
              boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-start"
              margin={1}
            >
              <IconButton
                onClick={() => {
                  useStore.getState().openGameChat_action();
                }}
              >
                <ChatIcon />
              </IconButton>
              <Stack direction="column" alignItems="left" margin={0.5}>
                {localGameChats.slice(-3).map((chat, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography variant="subtitle2">
                      {chat.timeString}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>{chat.username}</b>
                    </Typography>
                    <Typography>{chat.message}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Paper>
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          bottom: "0.5em",
          right: "0.5em",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(8px)",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box padding={1}>
            {controlScheme === "move" && <ControlCameraIcon />}
            {controlScheme === "none" && <CheckBoxOutlineBlankIcon />}
            {controlScheme === "fishing" && <PhishingIcon />}

            {activeControlType === "keyboard-directions" && <KeyboardIcon />}
            {activeControlType === "none" && <CheckBoxOutlineBlankIcon />}
            {activeControlType === "click-location" && <MouseIcon />}
            {activeControlType === "gamepad-directions" && <GamepadIcon />}
            {activeControlType === "gamepad-stick" && <SportsEsportsIcon />}
            {activeControlType === "touch-stick" && <SwipeIcon />}
            {activeControlType === "touch-location" && <TouchAppIcon />}
          </Box>
        </Paper>
      </Box>

      {!isGameChatOpen && isTouchSupported && controlScheme === "move" && (
        <Box
          {...bind()}
          sx={{
            width: 100,
            height: 100,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(8px)",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "50%",
            position: "absolute",
            bottom: "5em",
            left: "3em",
            overflow: "hidden",
            touchAction: "none",
          }}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "secondary.main",
              borderRadius: "50%",
              position: "absolute",
              top: 0,
              left: 0,
              transform: `translate(${40 + innerPosition.x}px, ${
                40 + innerPosition.y
              }px)`,
            }}
          />
        </Box>
      )}

      <Modal
        open={showGamepadBindingModal}
        onClose={handleCloseGamepadBindingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5">
            Gamepad Binding
          </Typography>

          <Divider />

          {step === 0 && (
            <>
              <Typography id="modal-modal-title" variant="h6">
                {gamepadSteps[step]}
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleGamepadStyle("nintendo")}
                sx={{ m: 0.5 }}
              >
                Nintendo
              </Button>
              <Button
                variant="contained"
                onClick={() => handleGamepadStyle("playstation")}
                sx={{ m: 0.5 }}
              >
                Playstation
              </Button>
              <Button
                variant="contained"
                onClick={() => handleGamepadStyle("xbox")}
                sx={{ m: 0.5 }}
              >
                Xbox
              </Button>
            </>
          )}

          {step === 1 && (
            <>
              <Typography id="modal-modal-title" variant="h6">
                {gamepadSteps[step]}
              </Typography>
              <Button
                variant="contained"
                onClick={handleGameFreeze}
                sx={{ m: 0.5 }}
              >
                Next
              </Button>
            </>
          )}

          {step > 1 && step < gamepadSteps.length && (
            <>
              <Typography id="modal-modal-title" variant="h6">
                {gamepadSteps[step][gamepadStyle]}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Value: {lastGamepadButton}
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleButtonPress(lastGamepadButton)}
                disabled={lastGamepadButton === "none"}
              >
                Map Button
              </Button>
            </>
          )}

          {step === gamepadSteps.length && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  console.log("restart");
                }}
                disabled={true}
              >
                Restart
              </Button>
              <Button variant="contained" onClick={handleGamepadSave}>
                Save
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={showInventoryModal}
        onClose={handleCloseInventoryModal}
        aria-labelledby="inventory-modal-title"
        aria-describedby="inventory-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" id="inventory-modal-title">
              Inventory
            </Typography>
            <IconButton onClick={handleCloseInventoryModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <InventoryScreen />
        </Box>
      </Modal>

      <Modal
        open={showFishingShopModal}
        onClose={handleCloseFishingShopModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 100 }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5">
            Fishing Shop
          </Typography>

          <Divider />

          <FishingShop />
        </Box>
      </Modal>

      <MinigameUIManager />
    </>
  );
};

export default UIManager;
