import { xpScaleBase, xpScaleExponent } from "./constants/progression";

export const getMinigameLevel = (currentXP) => {
  const A = xpScaleBase;
  const B = xpScaleExponent;

  // Calculate the level based on the XP
  // XP(L) = A * (L-1)^B
  let level = 1;
  while (true) {
    const requiredXP = A * Math.pow(level - 1, B);
    if (currentXP < requiredXP) {
      break;
    }
    level++;
  }

  return level - 1; // Level before it exceeds the XP
};

export const getRewardXP = (base = 10, level = 1) => {
  return base * level;
};

export const getMinXPForLevel = (level) => {
  const A = xpScaleBase;
  const B = xpScaleExponent;

  // Calculate the minimum XP needed for the given level
  // XP(L) = A * (L-1)^B
  if (level <= 1) {
    return 0; // Minimum XP for level 1 is 0
  }

  const minXP = A * Math.pow(level - 1, B);
  return minXP;
};
