import { useStore } from "../../zustand";
import { items } from "../../utility/constants/items";
import { getRandomFishFromRegion } from "../../utility/constants/creatures/fish";
import { useWebStore } from "../../zustand/web";
import { getRandomFloat } from "../../utility/random";
import { useFishingStore } from "../../zustand/fishing";
import {
  maxQTEDuration,
  maxQTEIndicatorSize,
  minQTEDuration,
  minQTEIndicatorSize,
} from "../../utility/constants/fishing";
import { devMsg } from "../../utility/devMsg";
import { getMinigameLevel, getRewardXP } from "../../utility/xp";
import { useProgressionStore } from "../../zustand/progression";

export const Cast = async () => {
  const qteRingSize = getRandomFloat(minQTEIndicatorSize, maxQTEIndicatorSize);
  const qteTargetDuration = getRandomFloat(minQTEDuration, maxQTEDuration);

  const itemIDofRod = useStore
    .getState()
    .getIDofEquippedItemSlot("Fishing Rod");

  const baitItemID = useStore
    .getState()
    .getIDofEquippedItemSlot("Fishing Bait");

  const rodPower = items[itemIDofRod].gameplay.fishing.power;
  const baitPower = items[baitItemID].gameplay.fishing.power;

  const equipmentPower = rodPower + baitPower;

  const maxQTEPower = equipmentPower * 0.5;

  useFishingStore.setState({
    canCast: false,
    isQTEPhase: true,
    qteRingSize: qteRingSize,
    qteTargetDuration: qteTargetDuration,
    equipmentPower: equipmentPower,
    maxQTEPower: maxQTEPower,
    baitItemID: baitItemID,
  });

  return true;
};

export const Catch = async () => {
  const userData = useWebStore.getState().userData;
  const equipmentPower = useFishingStore.getState().equipmentPower;
  const qteScore = useFishingStore.getState().qteScore;
  const baitItemID = useFishingStore.getState().baitItemID;
  const fishingXP = useProgressionStore.getState().fishingXP;

  const playerLevel = getMinigameLevel(fishingXP);

  const playerPower = (equipmentPower + qteScore) * playerLevel;

  const randomFish = getRandomFishFromRegion("Hub");

  console.log("randomFish level", randomFish.level);
  console.log("randomFish base XP", randomFish.baseXP);
  console.log(
    "randomFish xp granted",
    getRewardXP(randomFish.baseXP, randomFish.level),
  );

  devMsg(`EQUIPMENT POWER: ${equipmentPower}`);
  devMsg(`QTE SCORE: ${qteScore}`);
  devMsg(`PLAYER POWER: ${playerPower}`);
  devMsg(`RANDOM FISH POWER ${randomFish.name}: ${randomFish.power}`);

  let itemsToAdd = [];

  const didCatchFish = playerPower > randomFish.power;

  const request = [
    {
      itemId: baitItemID,
      count: 1,
    },
  ];

  const response = await useStore
    .getState()
    .destroyMultipleItems(request, false);

  if (didCatchFish) {
    for (let i = 0; i < randomFish.dropResults.length; i++) {
      itemsToAdd.push({
        itemId: randomFish.dropResults[i],
        count: 1,
      });
    }

    const oldFishingXP = useProgressionStore.getState().fishingXP;

    const newFishingXP =
      oldFishingXP + getRewardXP(randomFish.baseXP, randomFish.level);

    useProgressionStore.setState({ fishingXP: newFishingXP });

    await useProgressionStore.getState().updateProgression(userData.$id);

    await useStore
      .getState()
      .addMultipleToInventory(itemsToAdd, userData.username, true);
  } else {
    const oldFishingXP = useProgressionStore.getState().fishingXP;

    const newFishingXP = oldFishingXP + getRewardXP(5, playerLevel);

    useProgressionStore.setState({ fishingXP: newFishingXP });

    await useProgressionStore.getState().updateProgression(userData.$id);
  }

  useFishingStore.setState({ equipmentCheck: true });

  return response;
};
