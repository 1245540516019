import React, { useState } from "react";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import { Button } from "@mui/material";

let binaryString = "000001001000110100110010000001100011000001101110100000010001110000001110110110000000011010011010000011010001010000101110011111001001100001000000";

const cellSize = 30;

const P5Sketch = (p5) => {
  p5.setup = () => {
    const numChunks = binaryString.length / 144;
    const gridHeight = 8 * numChunks * cellSize; // Calculate the dynamic height
    p5.createCanvas(18 * cellSize, gridHeight); // Create the canvas
    p5.noLoop(); // Ensure the draw function is called once
  };

  p5.draw = () => {
    p5.background(255); // Set background color to white

    for (let i = 0; i < binaryString.length; i++) {
      const chunkIndex = Math.floor(i / 144);
      const localIndex = i % 144;
      const x = (localIndex % 18) * cellSize;
      const y = Math.floor(localIndex / 18) * cellSize + (chunkIndex * 8 * cellSize);

      if (binaryString[i] === '1') {
        p5.fill(0); // Set fill color to black
      } else {
        p5.fill(255); // Set fill color to white
      }

      p5.rect(x, y, cellSize, cellSize); // Draw the rectangle
    }
  };
};

function sketch(p5) {
  p5.setup = () => p5.createCanvas(600, 400, p5.WEBGL);

  p5.draw = () => {
    p5.background(250);
    p5.normalMaterial();
    p5.push();
    p5.rotateZ(p5.frameCount * 0.01);
    p5.rotateX(p5.frameCount * 0.01);
    p5.rotateY(p5.frameCount * 0.01);
    p5.plane(100);
    p5.pop();
  };
}

const Vik128 = () => {
  const [showSketch, setShowSketch] = useState(false)
  
  const toggleSketch = () => {
    setShowSketch(!showSketch)
  };

  return <>
    <Button variant="contained" onClick={toggleSketch}>Test</Button>
    {showSketch && <ReactP5Wrapper sketch={P5Sketch} />}
  </>;
};

export default Vik128;
