export const gamepadSteps = [
  "Choose your controller type",
  'Move all the sticks and press all the triggers. Then make sure nothing is pressed while hitting "Next".',
  {
    xbox: 'Press "A"',
    playstation: 'Press "X"',
    nintendo: 'Press "B"',
    map: "a",
  },
  {
    xbox: 'Press "X"',
    playstation: 'Press "SQUARE"',
    nintendo: 'Press "Y"',
    map: "x",
  },
  {
    xbox: 'Press "B"',
    playstation: 'Press "CIRCLE"',
    nintendo: 'Press "A"',
    map: "b",
  },
  {
    xbox: 'Press "Y"',
    playstation: 'Press "TRIANGLE"',
    nintendo: 'Press "X"',
    map: "y",
  },
  {
    xbox: 'Press "Up" on the D-Pad',
    playstation: 'Press "Up" on the D-Pad',
    nintendo: 'Press "Up" on the D-Pad',
    map: "up",
  },
  {
    xbox: 'Press "Down" on the D-Pad',
    playstation: 'Press "Down" on the D-Pad',
    nintendo: 'Press "Down" on the D-Pad',
    map: "down",
  },
  {
    xbox: 'Press "Left" on the D-Pad',
    playstation: 'Press "Left" on the D-Pad',
    nintendo: 'Press "Left" on the D-Pad',
    map: "left",
  },
  {
    xbox: 'Press "Right" on the D-Pad',
    playstation: 'Press "Right" on the D-Pad',
    nintendo: 'Press "Right" on the D-Pad',
    map: "right",
  },
  {
    xbox: 'Flick "L" up or down',
    playstation: 'Flick "Left Analogue" up or down',
    nintendo: 'Flick "Left Stick" up or down',
    map: "ls_up",
  },
  {
    xbox: 'Flick "L" left or right',
    playstation: 'Flick "Left Analogue" left or right',
    nintendo: 'Flick "Left Stick" left or right',
    map: "ls_left",
  },
  {
    xbox: 'Flick "R" up or down',
    playstation: 'Flick "Right Analogue" up or down',
    nintendo: 'Flick "Right Stick" up or down',
    map: "rs_up",
  },
  {
    xbox: 'Flick "R" left or right',
    playstation: 'Flick "Right Analogue" left or right',
    nintendo: 'Flick "Right Stick" left or right',
    map: "rs_left",
  },
  {
    xbox: 'Press "L"',
    playstation: 'Press "Left Analogue"',
    nintendo: 'Press "Left Stick"',
    map: "ls_click",
  },
  {
    xbox: 'Press "R"',
    playstation: 'Press "Right Analogue"',
    nintendo: 'Press "Right Stick"',
    map: "rs_click",
  },
  {
    xbox: 'Press "LB"',
    playstation: 'Press "L1"',
    nintendo: 'Press "L"',
    map: "lb",
  },
  {
    xbox: 'Press "RB"',
    playstation: 'Press "R1"',
    nintendo: 'Press "R"',
    map: "rb",
  },
  {
    xbox: 'Press "LT"',
    playstation: 'Press "L2"',
    nintendo: 'Press "ZL"',
    map: "lt",
  },
  {
    xbox: 'Press "RT"',
    playstation: 'Press "R2"',
    nintendo: 'Press "ZR"',
    map: "rt",
  },
  {
    xbox: 'Press "START"',
    playstation: 'Press "OPTIONS"',
    nintendo: 'Press "+"',
    map: "start",
  },
  {
    xbox: 'Press "VIEW"',
    playstation: 'Press "SHARE"',
    nintendo: 'Press "-"',
    map: "select",
  },
];
