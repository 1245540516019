import React from "react";
import { Box, Typography } from "@mui/material";
import { items } from "../../../utility/constants/items";
import { useStore } from "../../../zustand";
import { sortInventory } from "../../../utility/sortInventory";
import SortedInventory from "./storted";

const InventoryScreen = () => {
  const inventory = useStore((state) => state.inventory);
  const gold = useStore((state) => state.gold);
  const gems = useStore((state) => state.gems);

  const sortedInventory = sortInventory(inventory);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin={1}
      >
        <Typography variant="h5" component="div">
          Gold: {gold}
        </Typography>
        <Typography variant="h5" component="div">
          Gems: {gems}
        </Typography>
      </Box>

      <SortedInventory
        sortedInventory={sortedInventory}
        inventory={inventory}
        items={items}
      />
    </Box>
  );
};

export default InventoryScreen;
