import React, { useState, useEffect } from "react";
import { createDocument, getDocumentViaAttribute } from "../utility/CRUD";
import { createUserAccount } from "../utility/CRUD/session";
import {
  Paper,
  FormControl,
  TextField,
  Button,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useWebStore } from "../zustand/web";
import { useNavigate } from "react-router-dom";
import { starterInventory } from "../utility/constants/items";
import {
  starterFeats,
  starterProgression,
} from "../utility/constants/progression";

const { REACT_APP_DATABASE_ID, REACT_APP_IGLOO_DATA_ID } = process.env;

const Register = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    password: "",
    password2: "",
    name: "",
    username: "",

    // before, match, mismatch
    passwordMatch: "before",

    // before, verified, rejected
    usernameVerification: "before",

    // before, verified, rejected
    emailVerification: "before",
    registering: false,
  });

  const updateState = (newState) => {
    setState((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  const wipeText = () => {
    updateState({
      email: "",
      password: "",
      password2: "",
      name: "",
      username: "",
      passwordMatch: "before",
      usernameVerification: "before",
    });
  };

  const validateUsername = async () => {
    const validationResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "username",
      state.username,
    );

    if (!validationResponse.error) {
      if (validationResponse.total === 0) {
        updateState({
          usernameVerification: "verified",
          emailVerification: "before",
        });
      } else {
        updateState({
          usernameVerification: "rejected",
          emailVerification: "before",
        });
      }
    }
  };

  const validateEmail = async () => {
    const validationResponse = await getDocumentViaAttribute(
      REACT_APP_DATABASE_ID,
      REACT_APP_IGLOO_DATA_ID,
      "email",
      state.email,
    );

    if (!validationResponse.error) {
      if (validationResponse.total === 0) {
        return true;
      } else {
        console.log("EMAIL TAKEN");
        return false;
      }
    }
  };

  const register = async () => {
    updateState({registering: true});

    const isValidEmail = await validateEmail();

    if (isValidEmail) {
      const userAccount = await createUserAccount(
        state.email,
        state.password,
        state.name,
      );

      const emptyBlockedUsers = JSON.stringify({
        blocked_users: [],
      });

      const emptyFriendRequests = JSON.stringify({
        friend_requests: [],
      });

      const emptyFriends = JSON.stringify({
        friends: [],
      });

      const userData = {
        user_id: userAccount.$id,
        username: state.username,
        email: state.email,
        blocked_users: emptyBlockedUsers,
        friend_requests: emptyFriendRequests,
        friends: emptyFriends,
        progression: starterProgression,
        gold: 100,
        gems: 0,
        inventory: starterInventory,
        feats: starterFeats,
      };

      const userDoc = await createDocument(
        REACT_APP_DATABASE_ID,
        REACT_APP_IGLOO_DATA_ID,
        userData,
      );

      wipeText();

      updateState({ emailVerification: "verified" });

      useWebStore.setState({
        userAccount: userAccount,
        userData: userDoc,
        registering: false
      });

      setTimeout(function () {
        navigate("/account/profile");
      }, 2000);
    } else {
      updateState({ 
        emailVerification: "rejected",
        registering: false 
      });
    }
  };

  useEffect(() => {
    if (state.password === state.password2 && state.password !== "") {
      updateState({ passwordMatch: "match" });
    } else if (state.password !== "" && state.password2 !== "") {
      updateState({ passwordMatch: "mismatch" });
    }
  }, [state.password, state.password2]);

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="center">
        <Paper>
          <Box sx={{ margin: 2 }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="h6">Metaverse Registration</Typography>
              <FormControl>
                <TextField
                  id="tf-email"
                  label="Email"
                  variant="filled"
                  color="secondary"
                  type="email"
                  value={state.email}
                  onChange={(e) => updateState({ email: e.target.value })}
                  required={true}
                />
              </FormControl>

              <FormControl>
                <TextField
                  id="tf-password"
                  label="Password"
                  variant="filled"
                  type="password"
                  color="secondary"
                  value={state.password}
                  onChange={(e) => updateState({ password: e.target.value })}
                  required={true}
                />
                <TextField
                  id="tf-password-confirm"
                  label="Confirm Password"
                  variant="filled"
                  type="password"
                  color="secondary"
                  value={state.password2}
                  onChange={(e) => updateState({ password2: e.target.value })}
                  required={true}
                />
              </FormControl>

              <FormControl>
                <TextField
                  id="tf-name"
                  label="Name"
                  type="text"
                  variant="filled"
                  color="secondary"
                  value={state.name}
                  onChange={(e) => updateState({ name: e.target.value })}
                  required={true}
                />
                <TextField
                  id="tf-username"
                  label="Username"
                  type="text"
                  variant="filled"
                  color="secondary"
                  value={state.username}
                  onChange={(e) =>
                    updateState({
                      username: e.target.value,
                      usernameVerification: "before",
                    })
                  }
                  required={true}
                />

                <Stack direction="column" spacing={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      state.passwordMatch === "before" ||
                      state.passwordMatch === "mismatch" ||
                      state.username === "" ||
                      state.usernameVerification === "verified"
                    }
                    onClick={validateUsername}
                  >
                    Check Username
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      ( state.usernameVerification === "before" ||
                      state.usernameVerification === "rejected" ) ||
                      state.registering
                    }
                    onClick={register}
                  >
                    Register
                  </Button>
                </Stack>

                {state.usernameVerification === "rejected" && (
                  <Box sx={{ marginTop: 1 }}>
                    <Typography>Username Rejected</Typography>
                  </Box>
                )}

                {state.emailVerification === "rejected" && (
                  <Box sx={{ marginTop: 1 }}>
                    <Typography>Email Rejected</Typography>
                  </Box>
                )}

                {state.passwordMatch === "mismatch" && (
                  <Box sx={{ marginTop: 1 }}>
                    <Typography>Password Mismatch</Typography>
                  </Box>
                )}
              </FormControl>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Register;
