import { Canvas } from "@react-three/fiber";
import { useStore } from "../zustand";

import { Experience } from "./Experience";
import UIManager from "./UI";
import { Pregame } from "./Pregame";
import HandleController from "./controls/handleGamepads";
import { HandleKeyboard } from "./controls/handleKeyboard";
import LoadGeo from "./map";
import CameraManager from "./camera/cameraManager";
import Minigame from "./minigame";

function GameApp() {
  const pregameDone = useStore((state) => state.pregameDone);
  return (
    <>
      <Canvas shadows camera={{ position: [8, 8, 8], fov: 30 }}>
        <ambientLight />

        <color attach="background" args={["blue"]} />

        {!pregameDone && <Pregame />}
        {pregameDone && (
          <>
            <Experience />
            <HandleController />
            <HandleKeyboard />
            <CameraManager />
            <LoadGeo />
            <Minigame />
          </>
        )}
      </Canvas>
      <UIManager />
    </>
  );
}

export default GameApp;
