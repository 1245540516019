import React, { useRef, useEffect } from "react";
import { Group } from "three";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { Paper, Typography } from "@mui/material";

export function NPT({ colliders, ...props }) {
  const group = useRef();
  const collider = useRef(new Group());

  useFrame(() => {
    group.current.position.x = props.position[0];
    group.current.position.y = props.position[1];
    group.current.position.z = props.position[2];
    group.current.rotation.x = props.rotation[0];
    group.current.rotation.y = props.rotation[1];
    group.current.rotation.z = props.rotation[2];
  });

  useEffect(() => {
    const currentCollider = collider.current;
    const capturedColliders = colliders.current; // Capture current colliders value
    capturedColliders.push(currentCollider); // Use captured value

    // Cleanup function using the captured value
    return () => {
      const index = capturedColliders.indexOf(currentCollider);
      if (index > -1) {
        capturedColliders.splice(index, 1);
      }
    };
  }, [colliders]);

  return (
    <>
      <group ref={group}>
        <group ref={collider}>
          <mesh visible={true} position={[0, 1.26, 0]}>
            <boxGeometry args={[1.1, 2.52, 1.1]} />
            <meshStandardMaterial
              transparent={true}
              opacity={0.5}
              color="black"
            />
          </mesh>
        </group>
        <group position={[0, 2.5, 0]}>
          <Html zIndexRange={[2, 1]}>
            <Paper>
              <Typography>{props.username}</Typography>
            </Paper>
          </Html>
        </group>
        <mesh position={[0, 0.5, 0]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={props.bottomColor} />
        </mesh>
        <mesh position={[0, 1.5, 0]}>
          <boxGeometry args={[0.75, 1, 0.75]} />
          <meshStandardMaterial color={props.topColor} />
        </mesh>
        <mesh position={[0, 2.25, 0]}>
          <boxGeometry args={[0.5, 0.5, 0.5]} />
          <meshStandardMaterial color={props.hairColor} />
        </mesh>
      </group>
    </>
  );
}
