import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#5c7156",
      light: "#87A485",
      dark: "#242929",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9385a4",
      light: "#bab1c5",
      dark: "#6d5e7e",
      contrastText: "#000000",
    },
    info: {
      main: "#3d3d3d",
      light: "#d6d6d6",
      dark: "#1f1f1f",
    },
    error: {
      main: "#FFFFFF",
    },
  },
});

export default theme;
