import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { useStore } from "../../../zustand";
import { Vector3 } from "three";
import { Cast } from "../../fishing/Cast";
import { useFishingStore } from "../../../zustand/fishing";
import {
  durationSuffix,
  maxQTEDuration,
} from "../../../utility/constants/fishing";
import { getMinigameLevel, getMinXPForLevel } from "../../../utility/xp";
import { useProgressionStore } from "../../../zustand/progression";

const FishingUI = () => {
  const teleportPosition = new Vector3(0, 0, -3);

  const fishingXP = useProgressionStore((state) => state.fishingXP);
  const canCast = useFishingStore((state) => state.canCast);
  const isQTEPhase = useFishingStore((state) => state.isQTEPhase);
  const qteClock = useFishingStore((state) => state.qteClock);
  const qteTargetDuration = useFishingStore((state) => state.qteTargetDuration);

  const [fishingLevel, setFishingLevel] = useState(0);
  const [fishingXPofNextLevel, setFishingXPofNextLevel] = useState(0);
  const [fishingXPofLastLevel, setFishingXPofLastLevel] = useState(0);
  const [justOnce, setJustOnce] = useState(false);

  const qteFullDuration = maxQTEDuration + durationSuffix;

  const pixelScalar = 100;

  const Leave = () => {
    useStore.setState({ windowLock: false });
    useStore.getState().teleportAction(teleportPosition, "move");
  };

  useEffect(() => {
    const currentLevel = getMinigameLevel(fishingXP);
    const nextLevelXP = getMinXPForLevel(currentLevel + 1);
    const lastLevelXP = getMinXPForLevel(currentLevel);

    setFishingLevel(currentLevel);
    setFishingXPofNextLevel(nextLevelXP);
    setFishingXPofLastLevel(lastLevelXP);
    if (!justOnce) {
      const currentLevel = getMinigameLevel(fishingXP);
      const nextLevelXP = getMinXPForLevel(currentLevel + 1);
      const lastLevelXP = getMinXPForLevel(currentLevel);

      setFishingLevel(currentLevel);
      setFishingXPofNextLevel(nextLevelXP);
      setFishingXPofLastLevel(lastLevelXP);

      setJustOnce(true);
    }
  }, [justOnce, fishingXP]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: "0.5em",
          left: "50%",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(8px)",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            margin={1}
            padding={1}
            spacing={1}
          >
            <Button
              onClick={Cast}
              variant="contained"
              size="small"
              disabled={!canCast}
            >
              Cast
            </Button>
            <Button
              onClick={() => {
                useFishingStore.setState({ qtePressed: true });
              }}
              variant="contained"
              size="small"
              disabled={!isQTEPhase}
            >
              QTE
            </Button>
            <Button
              onClick={Leave}
              color="secondary"
              variant="contained"
              size="small"
            >
              Leave
            </Button>
            <Tooltip title={`${fishingXP} / ${fishingXPofNextLevel}`}>
              <div>
                <Typography>Fishing Level: {fishingLevel} </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    ((fishingXP - fishingXPofLastLevel) /
                      (fishingXPofNextLevel - fishingXPofLastLevel)) *
                    100
                  }
                />
              </div>
            </Tooltip>
          </Stack>
        </Paper>
      </Box>

      {isQTEPhase && (
        <Box
          position="absolute"
          top="5em"
          left="50%"
          transform="translate(-50%, 0)"
          zIndex="200"
          width={`${qteFullDuration * pixelScalar}px`}
          height={`${2.5 * 10}px`}
          backgroundColor="lightblue"
          border="2px solid green"
        />
      )}
      {isQTEPhase && (
        <Box
          position="absolute"
          top="5em"
          left="50%"
          transform="translate(-50%, 0)"
          zIndex="205"
          width={`${qteTargetDuration * pixelScalar}px`}
          height={`${2.5 * 10}px`}
          backgroundColor="lightblue"
          border="2px solid blue"
        />
      )}
      {isQTEPhase && (
        <Box
          position="absolute"
          top="5em"
          left="50%"
          transform={`translate(-50%, -50%)`}
          zIndex="210"
          width={`${qteClock * pixelScalar}px`}
          height={`${2.5 * 10}px`}
          backgroundColor="rgba(173, 216, 230, 0.5)" // lightblue with 50% opacity
          border="2px solid red"
        />
      )}
    </>
  );
};

export default FishingUI;
