const getFirstPart = (str, part) => {
  // find the index of the first underscore
  let index = str.lastIndexOf(part);
  // if there is no underscore, return the whole string
  if (index === -1) {
    return str;
  }
  // otherwise, return the substring from the start to the previous character
  return str.substring(0, index);
};

const getFilenameWithoutExtension = (filename) => {
  // Check if input is a string
  if (typeof filename !== "string") {
    throw new Error("Input must be a string.");
  }

  // Find the last index of a period in the string
  const lastIndexOfPeriod = filename.lastIndexOf(".");

  // If there is no period or the period is the first character, return the whole string
  if (lastIndexOfPeriod <= 0) {
    return filename;
  }

  // Extract and return the substring before the last period
  return filename.substring(0, lastIndexOfPeriod);
};

const caselessCompare = (str1, str2) => {
  return str1.toLowerCase() === str2.toLowerCase();
};

export { getFirstPart, getFilenameWithoutExtension, caselessCompare };
