export const ft_m = (ft) => ft * 0.3048;

export const cm_m = (cm) => cm / 100;

export const in_m = (i) => i * 0.0254;

export const round1 = (i) => Math.round(i * 10) / 10;

export const round2 = (i) => Math.round(i * 100) / 100;

export const round3 = (i) => Math.round(i * 1000) / 1000;
