import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Stack,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useStore } from "../../../zustand";
import { useWebStore } from "../../../zustand/web";
import { items } from "../../../utility/constants/items";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { sortInventory } from "../../../utility/sortInventory";
import SortedInventory from "../inventory/storted";

const FishingShop = () => {
  const gold = useStore((state) => state.gold);
  const gems = useStore((state) => state.gems);
  const inventory = useStore((state) => state.inventory);
  const userData = useWebStore.getState().userData;

  const [isBuying, setIsBuying] = useState(false);

  const sortedInventory = sortInventory(inventory);

  const buyItem = async (item_id, count) => {
    setIsBuying(true);

    const request = [
      {
        itemId: item_id,
        count: count,
      },
    ];

    const response = await useStore
      .getState()
      .addMultipleToInventory(request, userData.username);

    if (response) {
      console.log("Bought item");
    } else {
      console.log("ERROR buying item");
    }

    setIsBuying(false);
  };

  const sellAllJunk = async () => {
    setIsBuying(true);
    const response = await useStore.getState().sellAllJunkItems();
    if (response) {
      console.log("Sold all junk items");
    } else {
      console.log("ERROR selling junk items");
    }
    setIsBuying(false);
  };

  const shopItems = [
    {
      id: "82eb3dbc-ba3f-4931-b10f-fff02a4f1efa",
      count: 1,
    },
    {
      id: "82eb3dbc-ba3f-4931-b10f-fff02a4f1efa",
      count: 10,
    },
    {
      id: "d91f6070-dcab-4521-8c5a-038d3b8e31d5",
      count: 1,
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {shopItems.map((item) => {
          const shopItem = items[item.id];
          return (
            <Grid item xs={12} sm={6} md={4} key={item.id + item.count}>
              <Card>
                <CardContent>
                  <Stack margin={1} spacing={1} direction="column">
                    <Typography variant="h5" color="text.secondary">
                      {shopItem.name} | {item.count}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {shopItem.description}
                    </Typography>

                    <Stack direction="row" spacing={0} alignItems="center">
                      <AttachMoneyIcon />
                      <Typography variant="h6">
                        {shopItem.gold_buy_value * item.count}
                      </Typography>
                    </Stack>

                    <Stack margin={1} spacing={1} direction="row">
                      <Button
                        onClick={() => buyItem(item.id, item.count)}
                        variant="outlined"
                        color="error"
                        size="small"
                        disabled={isBuying}
                      >
                        Buy
                      </Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Typography variant="h5" component="div">
        Inventory
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin={1}
      >
        <Typography variant="h5" component="div">
          Gold: {gold}
        </Typography>
        <Typography variant="h5" component="div">
          Gems: {gems}
        </Typography>
      </Box>

      <Box margin={1}>
        <Button
          onClick={sellAllJunk}
          variant="outlined"
          color="error"
          disabled={isBuying}
        >
          Sell All Junk
        </Button>
      </Box>

      <SortedInventory
        sortedInventory={sortedInventory}
        inventory={inventory}
        items={items}
      />
    </Box>
  );
};

export default FishingShop;
