import { useThree } from "@react-three/fiber";
import { KTX2Loader } from "three/addons/loaders/KTX2Loader.js";
import { useStore } from "../zustand";
import { useWebStore } from "../zustand/web";

import testPic from "./target_bc.ktx2";
import testPic2 from "./target_opacity.ktx2";
import { getTouchScreenStatus } from "../utility/touch";

export function Pregame() {
  const renderer = useThree((state) => state.gl);

  const isTouchSupported = getTouchScreenStatus();

  const THREE_PATH = `https://unpkg.com/three@0.165.x`;
  const loader = new KTX2Loader();
  loader.setTranscoderPath(`${THREE_PATH}/examples/jsm/libs/basis/`);
  loader.detectSupport(renderer);

  loader.load(testPic2, function (tex) {
    useStore.setState({ clickHelperOpacityTexture: tex });
  });

  loader.load(testPic, function (tex) {
    useWebStore.setState({
      isTouchSupported: isTouchSupported,
    });
    useStore.setState({
      clickHelperBCTexture: tex,
      pregameDone: true,
      ktx2Loader: loader,
    });
  });

  loader.dispose();

  return null;
}
