import React, { useState, useRef, useEffect } from "react";
import { useStore } from "../../zustand";
import { OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { HandleGamepadCamera } from "./cameraHelpers";
import { poolLocation } from "../../utility/constants/hubConstants";

export const HandleFishingCamera = () => {
  const orbitRef = useRef();

  const gamepadData = useStore((state) => state.gamepadData);
  const activeControlType = useStore((state) => state.activeControlType);
  const gamepadMappings = useStore((state) => state.gamepadMappings);
  const controlScheme = useStore((state) => state.controlScheme);

  const [enableOC, setEnableOC] = useState(true);

  const camera = useThree((state) => state.camera);

  // Fixed point (origin) around which the camera rotates
  const [xthing, setXthing] = useState(Math.PI / 8);
  const [ything, setYthing] = useState(0);
  const [distance, setDistance] = useState(40);

  const maxDistance = 50;
  const minDistance = 10;

  useFrame(() => {
    if (
      (activeControlType === "gamepad-stick" ||
        activeControlType === "gamepad-directions") &&
      controlScheme.includes("fishing")
    ) {
      HandleGamepadCamera(
        gamepadData,
        gamepadMappings,
        distance,
        maxDistance,
        minDistance,
        setDistance,
        ything,
        xthing,
        setYthing,
        setXthing,
        camera,
        poolLocation,
      );
    }
  });

  useEffect(() => {
    if (activeControlType === "none") {
      setEnableOC(true);
    } else {
      setEnableOC(false);
    }
  }, [activeControlType]);

  return (
    <>
      <OrbitControls
        ref={orbitRef}
        enabled={enableOC}
        enableDamping={false}
        target={poolLocation}
        enablePan={false}
        minDistance={minDistance}
        maxDistance={maxDistance}
        onEnd={() => {
          setDistance(orbitRef.current.getDistance());
          setYthing(orbitRef.current.getAzimuthalAngle());
        }}
      />
    </>
  );
};
