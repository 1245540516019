import React, { useRef, useState } from "react";
import { Vector3 } from "three";
import { Player } from "./player";
import { useRealtimeStore } from "../zustand/realtime";
import { useStore } from "../zustand";
import { NPT } from "./NPT";
import { Obstacles } from "./Obstacles";

export const Experience = () => {
  const isGameTenant = useStore((state) => state.isGameTenant);
  const socket_id = useStore((state) => state.socket_id);
  const levelName = useStore((state) => state.levelName);

  const controlScheme = useStore((state) => state.controlScheme);

  const [pointerDownTime, setPointerDownTime] = useState(null);
  const TAP_THRESHOLD = 200; // Define the maximum duration (in milliseconds) for a tap

  const { realtime } = useRealtimeStore();

  const colliders = useRef([]);

  return (
    <>
      <mesh
        rotation-x={-Math.PI / 2}
        position-y={-0.01}
        onContextMenu={(e) => {
          if (isGameTenant && controlScheme === "move") {
            const target = new Vector3(e.point.x, 0, e.point.z);
            useStore.setState({
              tenantMovementTarget: target,
              firstMove: true,
              activeControlType: "click-location",
              clickHelperFade: "startFadeIn",
            });
          }
        }}
        onPointerDown={(e) => {
          if (isGameTenant && e.pointerType === "touch") {
            setPointerDownTime(Date.now());
          }
        }}
        onPointerUp={(e) => {
          if (isGameTenant && e.pointerType === "touch") {
            const pointerUpTime = Date.now();
            const duration = pointerUpTime - pointerDownTime;

            if (duration < TAP_THRESHOLD) {
              const target = new Vector3(e.point.x, 0, e.point.z);
              useStore.setState({
                tenantMovementTarget: target,
                firstMove: true,
                activeControlType: "click-location",
                clickHelperFade: "startFadeIn",
              });
            }
          }
        }}
      >
        <planeGeometry args={[60, 40]} />
        <meshStandardMaterial color="#777799" />
      </mesh>

      {Object.entries(realtime.realtime).map(([id, character]) => (
        <React.Fragment key={id}>
          {id === socket_id && (
            <Player
              // used by socket emit
              levelName={levelName}
              username={realtime.tenants[id].username}
              position={character.position}
              rotation={character.rotation}
              hairColor={"red"}
              topColor={"green"}
              bottomColor={"blue"}
              colliders={colliders}
            />
          )}

          {id !== socket_id && (
            <NPT
              username={realtime.tenants[id].username}
              position={character.position}
              rotation={character.rotation}
              hairColor={"red"}
              topColor={"green"}
              bottomColor={"blue"}
              colliders={colliders}
            />
          )}
        </React.Fragment>
      ))}

      <Obstacles colliders={colliders} />
    </>
  );
};
