export const getDropResult = (drop, atLeastOneItem) => {
  const result = [];
  const dropKeys = Object.keys(drop);
  const probabilities = Object.values(drop);

  // Calculate probabilities of each item dropping
  const dropSuccess = probabilities.map((prob) => Math.random() < prob);

  // If atLeastOneItem is true and none dropped, find the closest to dropping
  if (atLeastOneItem && dropSuccess.every((success) => !success)) {
    let closestIndex = probabilities.reduce(
      (prevIndex, currentProb, currentIndex) => {
        if (
          Math.abs(currentProb - 1) < Math.abs(probabilities[prevIndex] - 1)
        ) {
          return currentIndex;
        }
        return prevIndex;
      },
      0,
    );
    result.push(dropKeys[closestIndex]);
  } else {
    // Include all items that dropped
    dropSuccess.forEach((success, index) => {
      if (success) {
        result.push(dropKeys[index]);
      }
    });
  }

  return result;
};
