import { account, ID } from "../../lib/appwrite";

const getUserAccount = async (email, password) => {
  try {
    await account.createEmailSession(email, password);
    const userAccount = await account.get();

    return userAccount;
  } catch (error) {
    console.error("Login failed:", error);

    return { error: true, errorData: error };
  }
};

const createUserAccount = async (email, password, name) => {
  try {
    await account.create(ID.unique(), email, password, name);
    const userAccount = getUserAccount(email, password);
    return userAccount;
  } catch (error) {
    console.error("Registration failed:", error);

    return { error: true, errorData: error };
  }
};

const deleteSession = async () => {
  try {
    await account.deleteSession("current");
    return true;
  } catch (error) {
    console.error("Logout failed:", error);

    return { error: true, errorData: error };
  }
};

/**
 * This function tries to logout the current user.
 * If logging out is successful, the user is redirected to the logout page.
 *
 * @async
 * @function
 * @param {function} navigate - Provide a "navigate" function from react-router-dom useNavigate
 * @returns {string} error - if there is an error
 */
const Logout = async (navigate) => {
  const logoutResponse = await deleteSession();

  if (!logoutResponse.error) {
    navigate("/login/logout");
  } else {
    return { error: true, errorData: logoutResponse.errorData };
  }
};

export { getUserAccount, createUserAccount, deleteSession, Logout };
