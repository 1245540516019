import { Box3, Vector3 } from "three";

const checkTwoShapeIntersect = (object1, object2) => {
  const bBox1 = new Box3().setFromObject(object1);
  const bBox2 = new Box3().setFromObject(object2);

  const intersection = bBox1.intersectsBox(bBox2);

  let type = "obstacle";

  if (intersection && object2.name.includes("event")) {
    type = "event";
  }
  return { didIntersect: intersection, type: type };
};

export const calculateMTV = (object1, object2) => {
  const bBox1 = new Box3().setFromObject(object1);
  const bBox2 = new Box3().setFromObject(object2);

  const overlapX = Math.min(
    bBox1.max.x - bBox2.min.x,
    bBox2.max.x - bBox1.min.x,
  );
  const overlapY = Math.min(
    bBox1.max.y - bBox2.min.y,
    bBox2.max.y - bBox1.min.y,
  );
  const overlapZ = Math.min(
    bBox1.max.z - bBox2.min.z,
    bBox2.max.z - bBox1.min.z,
  );

  const mtvDirection = new Vector3();
  const minOverlap = Math.min(overlapX, overlapY, overlapZ);

  if (minOverlap === overlapX) {
    mtvDirection.set(bBox1.max.x < bBox2.max.x ? -overlapX : overlapX, 0, 0);
  } else if (minOverlap === overlapY) {
    mtvDirection.set(0, bBox1.max.y < bBox2.max.y ? -overlapY : overlapY, 0);
  } else {
    mtvDirection.set(0, 0, bBox1.max.z < bBox2.max.z ? -overlapZ : overlapZ);
  }

  return mtvDirection;
};

export const checkForCollisions = (currentCollider, colliders) => {
  for (let i = 0; i < colliders.current.length; i++) {
    if (colliders.current[i] !== currentCollider) {
      const collision = checkTwoShapeIntersect(
        currentCollider,
        colliders.current[i],
      );
      if (collision.didIntersect) {
        return { collider: colliders.current[i], type: collision.type };
      }
    }
  }
  return null;
};
