// itemCard.js

import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStore } from "../../../zustand";
import {
  AcUnit as AcUnitIcon,
  Cake as CakeIcon,
  Sell as SellIcon,
  Handshake as HandshakeIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Diamond as DiamondIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";

const REACT_APP_S3_BUCKET = process.env.REACT_APP_S3_BUCKET;

const ItemCard = ({ inventory, itemId, items, canSellItems }) => {
  const [isEquip, setIsEquip] = useState(false);

  const handleEquip = async (itemId, choice, slot) => {
    setIsEquip(true);
    await useStore.getState().toggleEquipStatus(itemId, choice, slot);
    setIsEquip(false);
  };

  const handleSell = async (itemId, count) => {
    setIsEquip(true);
    await useStore.getState().destroyMultipleItems([{ itemId, count }], true);
    setIsEquip(false);
  };

  const item = items[itemId];
  const inventoryItem = inventory[itemId];
  const filename = item.use_placeholder_icon ? "PLACEHOLDER" : itemId;
  const link = `https://${REACT_APP_S3_BUCKET}.s3.amazonaws.com/iglooverse/images/items/${filename}.webp`;

  const AttributeTooltip = ({ title, icon, value }) => (
    <Tooltip title={title}>
      <Paper variant="outlined" sx={{ padding: 0.5, backgroundColor: "black" }}>
        <Stack direction="row" spacing={0} alignItems="center">
          {icon} {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </Stack>
      </Paper>
    </Tooltip>
  );

  return (
    <Tooltip
      title={
        <Box sx={{ backgroundColor: "black", padding: 0.5 }}>
          <Typography variant="h6" component="div">
            <b>{item.name}</b> x{inventoryItem?.count}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {item.description}
          </Typography>
          <Divider />
          {!isEquip && (
            <Stack direction="column" spacing={0.5} alignItems="left">
              <Typography variant="body1" color="text.primary">
                <b>Type:</b> <i>{item.type}</i>
              </Typography>
              {item.equipment_slots.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" color="text.primary">
                    <b>Equipped: </b>
                    {inventoryItem.equipped === "Unequipped"
                      ? "No"
                      : `as ${inventoryItem.equipped}`}
                  </Typography>
                </Box>
              )}
              {item.equipment_slots.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    disabled={isEquip}
                    onClick={() =>
                      handleEquip(
                        itemId,
                        inventoryItem.equipped === "Unequipped",
                        item.equipment_slots[0],
                      )
                    }
                  >
                    {inventoryItem.equipped === "Unequipped"
                      ? "Equip"
                      : "Unequip"}
                  </Button>
                </Box>
              )}
              {item.equipment_slots.length === 0 && (
                <Typography variant="body1" color="text.primary">
                  <b>Not Equippable</b>
                </Typography>
              )}
              <Divider />
              <Stack direction="row" spacing={1}>
                <AttributeTooltip
                  title="Unique"
                  icon={<AcUnitIcon />}
                  value={inventoryItem.unique}
                />
                <AttributeTooltip
                  title="Consumable"
                  icon={<CakeIcon />}
                  value={item.consumable}
                />
                <AttributeTooltip
                  title="Sellable"
                  icon={<SellIcon />}
                  value={item.is_sellable}
                />
                <AttributeTooltip
                  title="Tradable"
                  icon={<HandshakeIcon />}
                  value={item.is_tradable}
                />
              </Stack>
              {item.gold_sell_value && (
                <Tooltip title="Sale Value">
                  <Stack direction="row" spacing={0} alignItems="center">
                    <AttachMoneyIcon />
                    <Typography variant="h6">{item.gold_sell_value}</Typography>
                  </Stack>
                </Tooltip>
              )}
              {item.gems_sell_value && (
                <Tooltip title="Sale Value">
                  <Box>
                    <Paper
                      variant="outlined"
                      sx={{ padding: 0.5, backgroundColor: "black" }}
                    >
                      <Stack direction="row" spacing={0} alignItems="center">
                        <DiamondIcon />
                        <Typography variant="h6">
                          {item.gems_sell_value}
                        </Typography>
                      </Stack>
                    </Paper>
                  </Box>
                </Tooltip>
              )}
              {item.is_sellable && canSellItems && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    disabled={isEquip}
                    onClick={() => handleSell(itemId, 1)}
                  >
                    Sell One
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    disabled={isEquip}
                    onClick={() => handleSell(itemId, inventoryItem.count)}
                  >
                    Sell All
                  </Button>
                </Stack>
              )}
              {item.stats &&
                Object.entries(item.stats).map(([statName, statValue]) => (
                  <Typography
                    key={statName}
                    variant="body2"
                    color="text.primary"
                  >
                    {statName}: {statValue}
                  </Typography>
                ))}
              <Divider />
            </Stack>
          )}
        </Box>
      }
    >
      <Box position="relative">
        <Box
          component="img"
          width="128px"
          height="128px"
          src={link}
          alt={item.name}
          sx={{
            border: `0.5em solid ${
              inventoryItem?.equipped === "Unequipped" ? "transparent" : "green"
            }`,
            borderRadius: 1,
          }}
        />
        {inventoryItem?.count > 1 && (
          <Box
            position="absolute"
            bottom={0}
            left={0}
            bgcolor="rgba(0, 0, 0, 1)"
            color="white"
            px={0.5}
            borderRadius="50%"
          >
            {inventoryItem.count}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default ItemCard;
