import React, { useEffect, useState, Suspense } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { MeshStandardMaterial } from "three";
import { useStore } from "../zustand";
import combinedData from "./hub";
import testPic2 from "../assets/ktx2/tropicalCity/TropicalCityTexture_01.ktx2";

const useLoadModels = (models, material, materialReady) => {
  const [loadedModels, setLoadedModels] = useState([]);
  const [justOnce, setJustOnce] = useState(false);

  useEffect(() => {
    if (!justOnce && materialReady) {
      setJustOnce(true);
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
      dracoLoader.preload();

      const loaders = models
        .map(
          (data) =>
            data.glb &&
            new Promise((resolve) => {
              const loader = new GLTFLoader();
              loader.setDRACOLoader(dracoLoader);
              loader.load(data.glb.path, (gltf) => {
                // Apply the material to the loaded scene
                gltf.scene.traverse((object) => {
                  if (object.isMesh) {
                    object.material = material;
                  }
                });
                resolve({ ...data.glb, scene: gltf.scene });
              });
            }),
        )
        .filter((loader) => loader); // Filter out null loaders

      Promise.all(loaders).then((loaded) => setLoadedModels(loaded));

      return () => {
        // Dispose of the loaders and resources
        dracoLoader.dispose();
        loaders.forEach((loaderPromise) =>
          loaderPromise.then((model) => {
            model.scene.traverse((object) => {
              if (object.isMesh) {
                object.geometry.dispose();
                if (object.material.isMaterial) {
                  object.material.dispose();
                }
              }
            });
          }),
        );
      };
    }
  }, [models, material, justOnce, materialReady]);

  return loadedModels;
};

const LoadGeo = () => {
  const ktx2Loader = useStore.getState().ktx2Loader;
  const [material, setMaterial] = useState(new MeshStandardMaterial());
  const [materialReady, setMaterialReady] = useState(false);

  useEffect(() => {
    ktx2Loader.load(testPic2, (texture) => {
      const matty = new MeshStandardMaterial({ map: texture });
      matty.needsUpdate = true;
      setMaterial(matty);
      setMaterialReady(true);
    });
  }, [ktx2Loader]);

  const models = combinedData.filter((data) => data.glb);

  const loadedModels = useLoadModels(models, material, materialReady);

  return (
    <Suspense fallback={null}>
      {loadedModels.map((model, index) => (
        <group key={index} position={model.position} rotation={model.rotation}>
          <primitive object={model.scene} scale={1} />
        </group>
      ))}
    </Suspense>
  );
};

export default LoadGeo;
