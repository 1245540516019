import { useEffect } from "react";
import { useStore } from "../../zustand";

export const HandleKeyboard = () => {
  const isGameChatOpen = useStore((state) => state.isGameChatOpen);

  const moveForward = useStore((state) => state.isKeyboardDirectionsForward);
  const moveBackward = useStore((state) => state.isKeyboardDirectionsBackward);
  const moveLeft = useStore((state) => state.isKeyboardDirectionsLeft);
  const moveRight = useStore((state) => state.isKeyboardDirectionsRight);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "w":
          if (!isGameChatOpen) {
            let firstMove = false;
            if (!moveForward && !moveBackward && !moveLeft && !moveRight) {
              firstMove = true;
            }
            useStore.setState({
              isKeyboardDirectionsForward: true,
              firstMove: firstMove,
              activeControlType: "keyboard-directions",
            });
          }
          break;
        case "s":
          if (!isGameChatOpen) {
            let firstMove = false;
            if (!moveForward && !moveBackward && !moveLeft && !moveRight) {
              firstMove = true;
            }
            useStore.setState({
              isKeyboardDirectionsBackward: true,
              firstMove: firstMove,
              activeControlType: "keyboard-directions",
            });
          }
          break;
        case "a":
          if (!isGameChatOpen) {
            let firstMove = false;
            if (!moveForward && !moveBackward && !moveLeft && !moveRight) {
              firstMove = true;
            }
            useStore.setState({
              isKeyboardDirectionsLeft: true,
              firstMove: firstMove,
              activeControlType: "keyboard-directions",
            });
          }
          break;
        case "d":
          if (!isGameChatOpen) {
            let firstMove = false;
            if (!moveForward && !moveBackward && !moveLeft && !moveRight) {
              firstMove = true;
            }
            useStore.setState({
              isKeyboardDirectionsRight: true,
              firstMove: firstMove,
              activeControlType: "keyboard-directions",
            });
          }
          break;
        case "Enter":
          useStore.getState().toggleGameChat_action();

          useStore.setState({
            isKeyboardDirectionsRight: false,
            isKeyboardDirectionsLeft: false,
            isKeyboardDirectionsFoward: false,
            isKeyboardDirectionsBackward: false,

            firstMove: false,
            activeControlType: "none",
          });
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.key) {
        case "w":
          if (!isGameChatOpen) {
            let activeControlType = "keyboard-directions";
            if (!moveBackward && !moveLeft && !moveRight) {
              activeControlType = "none";
            }
            useStore.setState({
              isKeyboardDirectionsForward: false,
              activeControlType: activeControlType,
            });
          }
          break;
        case "s":
          if (!isGameChatOpen) {
            let activeControlType = "keyboard-directions";
            if (!moveForward && !moveLeft && !moveRight) {
              activeControlType = "none";
            }
            useStore.setState({
              isKeyboardDirectionsBackward: false,
              activeControlType: activeControlType,
            });
          }
          break;
        case "a":
          if (!isGameChatOpen) {
            let activeControlType = "keyboard-directions";
            if (!moveBackward && !moveForward && !moveRight) {
              activeControlType = "none";
            }
            useStore.setState({
              isKeyboardDirectionsLeft: false,
              activeControlType: activeControlType,
            });
          }
          break;
        case "d":
          if (!isGameChatOpen) {
            let activeControlType = "keyboard-directions";
            if (!moveBackward && !moveLeft && !moveForward) {
              activeControlType = "none";
            }
            useStore.setState({
              isKeyboardDirectionsRight: false,
              activeControlType: activeControlType,
            });
          }
          break;
        case "i":
          const showInventoryModalLocal =
            useStore.getState().showInventoryModal;
          const windowLock = useStore.getState().windowLock;

          if (
            (!isGameChatOpen && !windowLock) ||
            (windowLock && showInventoryModalLocal)
          ) {
            useStore.setState({
              showInventoryModal: !showInventoryModalLocal,
              activeControlType: "keyboard-directions",
              windowLock: !showInventoryModalLocal,
            });
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [moveForward, moveBackward, moveLeft, moveRight, isGameChatOpen]);

  return null;
};
