import React, { useRef, useEffect } from "react";

import combinedData from "./hub";

const previewMode = false;

export const Obstacles = ({ colliders }) => {
  return (
    <>
      {combinedData.map((thing, index) => {
        if (thing.type.includes("obstacle") || thing.type.includes("event")) {
          return (
            <Obstacle
              key={index}
              position={thing.obstacle.position}
              dimensions={thing.obstacle.dimensions}
              rotation={thing.obstacle.rotation}
              colliders={colliders}
              visible={thing.obstacle.visible}
              type={thing.type}
              keyy={index}
            />
          );
        } else {
          return <React.Fragment key={index} />;
        }
      })}
    </>
  );
};

const Obstacle = ({
  position,
  dimensions,
  rotation,
  colliders,
  visible,
  type,
  keyy,
}) => {
  const obstacleRef = useRef();

  useEffect(() => {
    const currentObstacle = obstacleRef.current;
    const capturedColliders = colliders.current; // Capture current colliders value
    capturedColliders.push(currentObstacle); // Use captured value

    // Cleanup function using the captured value
    return () => {
      const index = capturedColliders.indexOf(currentObstacle);
      if (index > -1) {
        capturedColliders.splice(index, 1);
      }
    };
  }, [colliders]);

  return (
    <mesh
      ref={obstacleRef}
      position={position}
      rotation={rotation}
      visible={visible || previewMode}
      name={`${type}_${keyy}`}
    >
      <boxGeometry args={dimensions} />
      <meshStandardMaterial color={type === "event" ? "yellow" : "orange"} />
    </mesh>
  );
};
