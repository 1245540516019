import { gameplayStarter } from "../../gameplayStarter";
import { itemStarter } from "../../itemStarter";

export const rods = {
  "d91f6070-dcab-4521-8c5a-038d3b8e31d5": {
    ...itemStarter,
    name: "Basic Fishing Rod",
    type: "Fishing Rod",
    use_placeholder_icon: false,
    consumable: false,
    is_tradable: false,
    is_sellable: true,
    gold_buy_value: 100,
    gold_sell_value: 25,
    gems_buy_value: null,
    gems_sell_value: null,
    description: "A basic, durable rod for beginners",
    equipment_slots: ["Fishing Rod", "Main Hand Weapon"],
    unique: false,
    gameplay: {
      ...gameplayStarter,
      fishing: {
        power: 100,
      },
      combat: {
        damage: 5,
      },
    },
    stats: {
      "Fishing Power": 100,
      Damage: 5,
    },
  },
};
